import { Plugin } from '@ckeditor/ckeditor5-core';
import React from 'react';
import ReactDOM from 'react-dom';
import { documentApi } from '../../api/document';
import { EditorConfig } from '../../config/EditorConfig';

export class QualioDocumentMention extends Plugin {
  static get pluginName(): string {
    return 'QualioDocumentMention';
  }

  init(): void {
    const editor = this.editor;
    const downcastConversion: any = editor.conversion.for('downcast');
    const upcastConversion: any = editor.conversion.for('upcast');

    upcastConversion.elementToAttribute({
      view: {
        name: 'a',
        key: 'data-mention',
        classes: 'docreference',
        attributes: {
          href: true,
          'data-code': true,
        },
      },
      model: {
        key: 'mention',
        value: (viewItem: any) => {
          const mentionPlugin: any = editor.plugins.get('Mention');
          return mentionPlugin.toMentionAttribute(viewItem, {
            code: viewItem.getAttribute('data-code'),
          });
        },
      },
      converterPriority: 'high',
    });

    downcastConversion.attributeToElement({
      model: 'mention',
      view: (modelAttributeValue: any, { writer }: any) => {
        if (!modelAttributeValue) {
          return;
        }
        if (!modelAttributeValue.code) {
          return writer.createAttributeElement('span');
        }
        return writer.createAttributeElement(
          'a',
          {
            class: 'docreference',
            'data-mention': modelAttributeValue.id,
            'data-code': modelAttributeValue.code,
            href: `${window.location.protocol}//${window.location.host}/reference/${modelAttributeValue.code}`,
            target: '_blank',
          },
          {
            priority: 20,
            id: modelAttributeValue.uid,
          },
        );
      },
      converterPriority: 'high',
    });
  }
}

const renderJSX = (jsxElement: any) => {
  const itemContainer = document.createElement('div');
  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(jsxElement, itemContainer);
};

const renderBalloonMentionable = (item: any) => {
  return renderJSX(
    <span className="custom-item" id={`mention-list-item-id-${item.id}`}>
      <span className="ck">{item.text ? item.text : 'Begin typing to search...'}</span>
    </span>,
  );
};

const extractMentionable = (item: any) => {
  return {
    id: `@${item.code}`,
    code: item.code,
    text: `${item.code} ${item.title}`,
  };
};

const getMentionable =
  (companyId: number) =>
  (queryText: string): Promise<any> => {
    const trimmedQuery = queryText.trim();
    if (trimmedQuery.length === 0) {
      return Promise.resolve([
        {
          id: `@`,
          code: '',
          text: ``,
        },
      ]);
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        void documentApi
          .smartLinksSuggest(companyId, {
            search: trimmedQuery,
          })
          .then((items: any) => {
            const first = items.slice(0, 10).map(extractMentionable);
            resolve(first);
          });
      }, EditorConfig.EditorSmartlinkSuggestTimeout);
    });
  };

export const getMentionConfig = (companyId: number): any => ({
  feeds: [
    {
      marker: '@',
      feed: getMentionable(companyId),
      itemRenderer: renderBalloonMentionable,
    },
  ],
});
