import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Bold, Italic, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { Plugin } from '@ckeditor/ckeditor5-core';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { FontBackgroundColor, FontColor, FontFamily, FontSize } from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
} from '@ckeditor/ckeditor5-image';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { List, ListProperties } from '@ckeditor/ckeditor5-list';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import {
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
} from '@ckeditor/ckeditor5-special-characters';
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';
import { FileRepository } from '@ckeditor/ckeditor5-upload';
import { QualioCodeBlockPlugin } from '../plugins/CodeBlock/QualioCodeBlockPlugin';
import { QualioDisableSectionHeadingPlugin } from '../plugins/LockSectionHeaders/QualioDisableSectionHeadingPlugin';
import { QualioSectionHeadingPlugin } from '../plugins/SectionHeaders/QualioSectionHeadingPlugin';
import { CKEditorConfig } from './CKEditorConfig';
import { Mention } from '@ckeditor/ckeditor5-mention';
import { PageBreak } from '@ckeditor/ckeditor5-page-break';
import { CONTENT_EDITOR_TOOLBAR_ITEMS } from './ToolbarItems';
import {
  getMentionConfig as getQualioDocumentMentionConfig,
  QualioDocumentMention,
} from '../plugins/Mention/QualioDocumentMention';

declare global {
  const CKEditor5: any;
}

export class EditorConfig {
  public static CONTENT_EDITOR_PLUGINS: (typeof Plugin)[] = [
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    BlockQuote,
    HorizontalLine,
    Essentials,
    Paragraph,
    Bold,
    FontColor,
    FontSize,
    FontFamily,
    FontBackgroundColor,
    Image,
    ImageInsert,
    ImageUpload,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    PageBreak,
    ImageToolbar,
    LinkImage,
    MediaEmbed,
    Indent,
    IndentBlock,
    RemoveFormat,
    Italic,
    Link,
    List,
    ListProperties,
    QualioSectionHeadingPlugin,
    PasteFromOffice,
    Mention,
    QualioDisableSectionHeadingPlugin,
    QualioCodeBlockPlugin,
    FileRepository,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    SpecialCharactersEssentials,
    Strikethrough,
    Superscript,
    Subscript,
    Heading,
    CodeBlock,
    Underline,
    Table,
    TableColumnResize,
    TableToolbar,
    TableProperties,
    TableCellProperties,
    TableCaption,
    TextTransformation,
  ];

  private static EXTERNAL_PLUGINS_ADDED = false;

  private static addExternalPlugins() {
    if (EditorConfig.EXTERNAL_PLUGINS_ADDED) {
      return;
    }

    if (CKEditor5.fileUpload.QualioFileUpload) {
      EditorConfig.CONTENT_EDITOR_PLUGINS.push(CKEditor5.fileUpload.QualioFileUpload);
    }
    if (CKEditor5.sectionList.QualioSectionList) {
      EditorConfig.CONTENT_EDITOR_PLUGINS.push(CKEditor5.sectionList.QualioSectionList);
    }
    if (CKEditor5.checkbox.QualioCheckbox) {
      EditorConfig.CONTENT_EDITOR_PLUGINS.push(CKEditor5.checkbox.QualioCheckbox);
    }
  }

  public static getContentEditorPlugins() {
    EditorConfig.addExternalPlugins();
    return EditorConfig.CONTENT_EDITOR_PLUGINS;
  }

  public static readonly TABLE_TOOLBAR_ITEMS: string[] = [
    'tableColumn',
    'tableRow',
    'mergeTableCells',
    'toggleTableCaption',
    'tableProperties',
    'tableCellProperties',
  ];

  public static readonly IMAGE_TOOLBAR_ITEMS: string[] = [
    'toggleImageCaption',
    'imageTextAlternative',
    '|',
    'imageStyle:alignLeft',
    'imageStyle:alignRight',
    'imageStyle:alignBlockLeft',
    'imageStyle:alignBlockRight',
    'imageStyle:alignCenter',
  ];

  public static readonly EditorSmartlinkSuggestTimeout = 100;
}

export const getCKEditorConfig = (
  companyId: number,
  isSmartlinkEverythingEnabled: boolean,
  toolbarItems: string[] = CONTENT_EDITOR_TOOLBAR_ITEMS,
): CKEditorConfig => {
  return {
    companyId: companyId,
    plugins: isSmartlinkEverythingEnabled
      ? [...EditorConfig.getContentEditorPlugins(), CKEditor5.smartlink.QualioSmartlink]
      : [...EditorConfig.getContentEditorPlugins(), QualioDocumentMention],
    toolbar: {
      items: toolbarItems,
      shouldNotGroupWhenFull: false,
    },
    licenseKey: process.env.REACT_APP_CKE_PREMIUM_FEATURES_LICENSE_KEY,
    indentBlock: {
      offset: 2,
      unit: 'em',
    },
    table: {
      contentToolbar: EditorConfig.TABLE_TOOLBAR_ITEMS,
    },
    image: {
      toolbar: EditorConfig.IMAGE_TOOLBAR_ITEMS,
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_p',
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_h1',
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_h2',
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_h3',
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_h4',
        },
      ],
    },
    list: {
      properties: {
        styles: true,
        startIndex: false,
        reversed: false,
      },
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
    mention: isSmartlinkEverythingEnabled
      ? {
          ...CKEditor5.smartlink.QualioSmartlink.getMentionConfig(
            companyId,
            process.env.REACT_APP_GLOBAL_SEARCH_SERVICE_URL,
          ),
        }
      : {
          ...getQualioDocumentMentionConfig(companyId), // Use getMatrixIdMentionConfig(companyId) for new version of Mention
        },
    fontFamily: {
      options: ['default'],
    },
    fontSize: {
      options: [
        'small',
        {
          title: 'Normal',
          model: '14px',
          upcastAlso: [],
          view: '',
        },
        {
          title: 'Large',
          model: '19.6px',
          upcastAlso: [],
          view: '',
        },
      ],
    },
    simpleFileUpload: {
      url: process.env.REACT_APP_MEDTECH_BACKEND_API_URL + '/attachments',
      fileTypes: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.jpg', '.png'],
    },
    simpleUpload: {
      uploadUrl: process.env.REACT_APP_MEDTECH_BACKEND_API_URL + '/attachments',
      withCredentials: true,
    },
  };
};

const balloonPanelStyle = `
  .ck-balloon-panel_visible.ck-balloon-panel_with-arrow.ck-toolbar-container {
    z-index: 998 !important;
  }
`;

const documentLayoutStyle = `
  .gray-layout {
    padding: 0px !important;
  } 
`;

export const balloonPanelStyleSheet = document.createElement('style');
balloonPanelStyleSheet.innerText = balloonPanelStyle;

export const documentLayoutInnerStyleSheet = document.createElement('style');
documentLayoutInnerStyleSheet.innerText = documentLayoutStyle;
