import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
  useCurrentUser,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { User } from '../../types/user';
import TaskForm from './components/TaskForm';
import { TaskCreate, Task } from '../../types';
import tasksApi from '../../api/tasks.api';
import { DefaultErrorMessage } from '../../displayStrings';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectsData: {
    users: User[];
  };
  stepId: number;
};

const TaskNewModal: React.FC<Props> = ({ isOpen, setIsOpen, selectsData, stepId }) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  const formMethods = useForm<TaskCreate>({
    mode: 'onSubmit',
    resolver: zodResolver(TaskCreate),
    defaultValues: {
      time_limit: 60,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const { mutate: createTaskMutate, isLoading: isCreateTaskLoading } = useMutation(
    (payload: TaskCreate) => tasksApi.create(companyId, { ...payload, step_id: stepId }),
    {
      onSuccess: (data: Task) => {
        showToast({
          title: 'Task created',
          description: `${data.title} was created`,
          status: 'success',
        });
        handleClose();
        queryClient.invalidateQueries({ queryKey: ['userTasksList'] });
      },
      onError: () => {
        showToast({
          title: 'Task not created',
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );

  const onSubmit = (payload: TaskCreate) => {
    createTaskMutate(payload);
  };

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Create task</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <TaskForm formMethods={formMethods} onSubmit={onSubmit} data={selectsData} />
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          Cancel
        </QButton>
        <QButton onClick={handleSubmit(onSubmit)} isDisabled={formHasErrors} isLoading={isCreateTaskLoading}>
          Create task
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default TaskNewModal;
