import * as z from 'zod';

export const DigitalSignature = z.object({
  email: z.string(),
  password: z.string(),
  comment: z.string(),
});

export type DigitalSignature = z.infer<typeof DigitalSignature>;

export const DigitalSignatureInput = z.object({
  headingText: z.string(),
  subText: z.string(),
  submitButtonText: z.string(),
  successToastHeader: z.string(),
  successToastDescription: z.string(),
});

export type DigitalSignatureInput = z.infer<typeof DigitalSignatureInput>;
