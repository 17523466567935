import React, { useMemo, useState } from 'react';
import {
  useCurrentUser,
  QBox,
  QHeading,
  QButtonGroup,
  QButton,
  QSpinner,
  QStack,
  QTag,
  QSelect,
  QSelectItem,
  CurrentUserContextType,
  QFormControl,
  deprecated,
} from '@qualio/ui-components';
import { EventType } from '../../types';
import { useNavigate } from 'react-router-dom';
import TemplateRowActions from './TemplateRowActions';
import { useGetTemplates } from './hooks';
import { ClickPropagationBarrier } from '../../components/';
import { useDocTitle } from '../../hooks';
import { DateTimeFormat, useLocalisedFormatDateTime } from '../../utils/datetimeUtils';

const columns: deprecated.QDataColumn[] = [
  { Header: 'Prefix', accessor: 'prefix', width: '5%' },
  { Header: 'Title', accessor: 'name' },
  { Header: 'Default time limit', accessor: 'defaultTimeLimit', width: '10%' },
  { Header: 'Last modified', accessor: 'lastModified', width: '10%' },
  {
    Header: 'Status',
    accessor: 'status',
    width: '10%',
    sortType: (rowA, rowB) => {
      const statusA = rowA.original.active ? 'effective' : 'archived';
      const statusB = rowB.original.active ? 'effective' : 'archived';

      if (statusA === statusB) {
        return 0;
      }

      return statusA < statusB ? -1 : 1;
    },
  },
  { Header: '', accessor: 'actions', width: '5%', disableSortBy: true },
];

type TemplateStatusValues = 'active' | 'archived' | 'all';
type TemplateStatusLabels = 'Effective' | 'Archived' | 'All';
type StatusFilterOption = QSelectItem<TemplateStatusLabels, TemplateStatusValues>;

// Used by status filter dropdown
const statusSelectOptions: ReadonlyArray<StatusFilterOption> = [
  { value: 'active', label: 'Effective' },
  { value: 'archived', label: 'Archived' },
  { value: 'all', label: 'All' },
] as const;

const statusFilterFunctions = {
  active: (template: EventType) => template.active,
  archived: (template: EventType) => !template.active,
  all: (_template: EventType) => true,
} as const;

// Converts an Event template into data structured for the QDataTable
const mapEventTemplate = (
  companyId: CurrentUserContextType['companyId'],
  formatDateTime: (
    timestamp: number | string,
    dateTimeFormat?: string | DateTimeFormat,
    includeTime?: boolean,
  ) => string,
  template: EventType,
) => {
  const { id, name, prefix, defaultTimeLimit, updatedAt, active } = template;

  return {
    id,
    active,
    name,
    prefix: <div data-cy={`event-template-prefix-${prefix}`}>{prefix}</div>,
    defaultTimeLimit: `${defaultTimeLimit} days`,
    lastModified: updatedAt ? formatDateTime(updatedAt, DateTimeFormat.DISPLAY_DATE, false) : '',
    status: active ? <QTag variantColor="green">Effective</QTag> : <QTag variantColor="red">Archived</QTag>,
    actions: (
      <ClickPropagationBarrier data-cy={`event-template-action-${id}`}>
        <TemplateRowActions templateId={id} templateActive={active} companyId={companyId} />
      </ClickPropagationBarrier>
    ),
  };
};

const QualityEventTemplates = () => {
  const { companyId } = useCurrentUser();
  const navigate = useNavigate();
  const formatDateTime = useLocalisedFormatDateTime();
  const { data: templates, isLoading } = useGetTemplates(companyId);
  const [filter, setFilter] = useState<TemplateStatusValues>('active');
  const tableData: deprecated.QDataRow[] = useMemo(() => {
    if (!templates) {
      return [];
    }

    const filterOnStatus = statusFilterFunctions[filter];
    const templateMapper = mapEventTemplate.bind(null, companyId, formatDateTime);

    return templates.filter(filterOnStatus).map(templateMapper);
  }, [templates, filter, companyId, formatDateTime]);
  const handleRowClick = (row: deprecated.QDataRow) => navigate(`/templates/${row.id}`);

  useDocTitle('Event templates - Qualio');

  return (
    <QStack direction="column" spacing={6}>
      <QBox display="flex" flexDirection="row" justifyContent="space-between">
        <QHeading size="lg">Event templates</QHeading>
        <QButtonGroup>
          <QButton data-cy={'create-template'} variant="solid" onClick={() => navigate('/templates/new')}>
            Create template
          </QButton>
        </QButtonGroup>
      </QBox>
      <QFormControl data-cy={'event-templates-filter'} w="300px">
        <QSelect
          value={filter}
          options={statusSelectOptions}
          onChange={(changeItem) => changeItem && setFilter(changeItem.value)}
          isDisabled={false}
          isLoading={false}
          isSearchable={false}
          filterOption={null}
        />
      </QFormControl>
      {isLoading ? (
        <QBox w="100%" textAlign="center" p={5} mt={16}>
          <QSpinner />
        </QBox>
      ) : (
        <QBox data-cy={'event-templates-table'}>
          <deprecated.QDataTable hasPageSizeOptions columns={columns} data={tableData} onRowClick={handleRowClick} />
        </QBox>
      )}
    </QStack>
  );
};

export default QualityEventTemplates;
