import { QTag } from '@qualio/ui-components';
import React from 'react';

const mapStatusToColor = (status: string) => {
  switch (status) {
    case 'open':
      return 'blue';
    case 'closed':
      return 'green';
    case 'imported':
      return 'default';
    default:
      return 'default';
  }
};

const mapStatusToLabel = (status: string) => {
  switch (status) {
    case 'open':
      return 'Open';
    case 'closed':
      return 'Closed';
    case 'imported':
      return 'Imported';
    default:
      return status;
  }
};

type Props = {
  status: string | undefined;
};

const StatusTag: React.FC<Props> = ({ status }) => {
  if (!status) {
    return <>{''}</>;
  }

  return <QTag variantColor={mapStatusToColor(status || '')}>{mapStatusToLabel(status)}</QTag>;
};

export default StatusTag;
