import { EventTag, User } from '../types';
import { UseFormSetValue } from 'react-hook-form';
import { QualityEvent, QualityEventCreate } from '../types';

export const filterTags = (userId: number, usersData: User[], tagsData: EventTag[]): EventTag[] => {
  const userGroups = usersData?.find((user) => user.id === userId)?.groups;
  return tagsData?.filter((tag) => {
    if (!tag.group_ids?.length) {
      return tag;
    }
    const groupIntersection = userGroups?.filter((groupId) => tag.group_ids?.includes(groupId));
    if (groupIntersection?.length) {
      return tag;
    }
    return false;
  });
};

const stringify = (val: unknown): string | undefined => (val === undefined || val === null ? undefined : String(val));

export const eventFieldsSetter = (event: QualityEvent | undefined, setValue: UseFormSetValue<QualityEventCreate>) => {
  if (!event) {
    return;
  }
  // Setting the below values so that we can use the edit event endpoint
  setValue('title', event.title);
  setValue('workflow_id', String(event.workflow_id));
  setValue('owner_id', String(event.owner_id));
  setValue('description', event.description);
  setValue('related_entity_ids', event.related_entity_ids);
  setValue('suppliers', event.suppliers);
  setValue('related_documents', event.related_documents);
  setValue('related_events', event.related_events);
  setValue('severity_id', stringify(event.severity_id));
  setValue('probability_id', stringify(event.probability_id));
  setValue('risk_id', stringify(event.risk_id));
  if (event.tags_ids) {
    setValue('tags_ids', event.tags_ids.map(String));
  }
  setValue('time_limit', event.time_limit);
  setValue('product_id', stringify(event.product_id));
  setValue('rootcause_id', stringify(event.rootcause_id));
};
