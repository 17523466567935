import * as z from 'zod';

import { DateSchema, NumberSchema } from './common/processed';
import { EventTemplateFieldsSchema } from './eventField';
import { gtTenYearsDaysErrorMessage, ltZeroDaysErrorMessage, tenYearsDays } from './common/constants';
import { EscalationDetailsSchema } from './escalationDetails';

export const QualityEventStatus = z.enum(['open', 'closed', 'overdue']);
export type QualityEventStatus = z.infer<typeof QualityEventStatus>;

export const IntegrationFields = z.record(z.string(), z.any());
export type IntegrationFields = z.infer<typeof IntegrationFields>;

export const QeSource = z.object({
  company_id: z.number(),
  created_at: DateSchema,
  id: NumberSchema,
  name: z.string(),
  type: z.string(),
  url: z.string(),
});

export type QeSource = z.infer<typeof QeSource>;

export const ListQualityEvent = z.object({
  id: NumberSchema,
  uuid: z.string(),
  code: z.string(),
  title: z.string(),
  created_at: DateSchema,
  updated_at: DateSchema,
  resolved_at: DateSchema.nullable(),
  description: z.string(),
  workflow_id: z.number(),
  workflow: z.string(),
  due_at: DateSchema,
  status: z.string(),
  owner: z.string().nullable(),
  owner_id: z.number(),
  creator: z.string().nullable(),
  risk: z.string().optional().nullable(),
  risk_id: z.number().optional().nullable(),
  severity_id: z.number().optional().nullable(),
  probability_id: z.number().optional().nullable(),
  product_id: z.number().optional().nullable(),
  product_name: z.string().optional().nullable(),
  rootcause_id: z.number().optional().nullable(),
  time_limit: z.number(),
  tags: z.string().default(''), // TODO: this should be removed and tags_ids should be used
  tags_ids: z.array(z.number()),
  steps_closed: z.number(),
  steps_total: z.number(),
  template_fields: EventTemplateFieldsSchema,
  rootcause_name: z.string().optional().nullable(),
});

export const QualityEvent = ListQualityEvent.extend({
  email: z.string().optional().nullable(),
  company_id: z.number().optional(),
  severity: z.string().optional().nullable(),
  probability: z.string().optional().nullable(),
  related_entity_ids: z.array(z.string()),
  suppliers: z.array(z.string()).default([]),
  related_documents: z
    .array(z.string())
    .optional()
    .transform((val) => val ?? []),
  related_events: z
    .array(z.string())
    .optional()
    .transform((val) => val ?? []),
  current_step: z.string(),
  acknowledged_at: DateSchema.nullable(),
  qe_source: QeSource.nullable(),
  fields: IntegrationFields.nullable(),
  escalated_to: z.array(EscalationDetailsSchema).optional().nullable(),
  escalated_from: z.array(EscalationDetailsSchema).optional().nullable(),
});
export type ListQualityEvent = z.infer<typeof ListQualityEvent>;
export type QualityEvent = z.infer<typeof QualityEvent>;

export const QualityEventSyncSchema = QualityEvent.pick({
  id: true,
}).merge(
  z.object({
    template_fields: EventTemplateFieldsSchema,
  }),
);

export type QualityEventSync = z.infer<typeof QualityEventSyncSchema>;

export const QualityEventListResponse = z.object({
  data: z.array(ListQualityEvent),
  meta: z.object({
    page: z.object({
      page_size: z.number(),
      total_items: z.number(),
      total_pages: z.number(),
    }),
  }),
});

export type QualityEventListResponse = z.infer<typeof QualityEventListResponse>;

export const QualityEventsGet = z.object({
  event_type: z.string().optional(),
  created_at_start_date: z.string().optional(),
  created_at_end_date: z.string().optional(),
  order_by: z.string().optional(),
  status: z.array(z.string()).optional(),
  owner_id: z.string().optional(),
  filter_tags: z.array(z.string()).optional(),
  product_id: z.string().optional(),
  rootcause_id: z.string().optional(),
  is_overdue: z.string().optional(),
  page_number: z.number().optional(),
  page_size: z.number().optional(),
  term: z.string().optional(),
});

export type QualityEventsGet = z.infer<typeof QualityEventsGet>;

const TitleLengthRestriction = z.object({
  title: z.string().max(100, 'Title can be no longer than 100 characters'),
});

export const QualityEventCreate = z.object({
  title: z.string(),
  workflow_id: z.string(),
  description: z.string().optional(),
  probability_id: z.string().optional(),
  severity_id: z.string().optional(),
  risk_id: z.string().optional(),
  owner_id: z.string(),
  related_entity_ids: z.array(z.string()).optional(),
  suppliers: z.array(z.string()).optional(),
  related_documents: z.array(z.string()).optional(),
  related_events: z.array(z.string()).optional(),
  tags_ids: z.array(z.string()).optional(),
  time_limit: z.preprocess(
    (val) => (val ? Number(val) : val),
    z
      .number({
        invalid_type_error: 'Must be a number',
      })
      .int('Must be a whole number')
      .min(0, ltZeroDaysErrorMessage)
      .max(tenYearsDays, gtTenYearsDaysErrorMessage),
  ),
  product_id: z.string().optional(),
  rootcause_id: z.string().optional(),
  escalated_to: z.array(NumberSchema).optional(),
  escalated_from: z.array(NumberSchema).optional(),
});
export const QualityEventCreateWithTitleRestriction = QualityEventCreate.merge(TitleLengthRestriction);

export const QualityEventEdit = QualityEventCreate.extend({
  status: z.string(),
});
export const QualityEventEditWithTitleRestriction = QualityEventEdit.merge(TitleLengthRestriction);

export type QualityEventCreate = z.infer<typeof QualityEventCreate>;
export type QualityEventEdit = z.infer<typeof QualityEventEdit>;
export type QualityEventCreateWithTitleRestriction = z.infer<typeof QualityEventCreateWithTitleRestriction>;
export type QualityEventEditWithTitleRestriction = z.infer<typeof QualityEventEditWithTitleRestriction>;

export const QualityEventUpdate = QualityEventCreate.omit({ workflow_id: true });
export type QualityEventUpdate = z.infer<typeof QualityEventUpdate>;

export const EventSortParam = z.object({
  id: z.enum([
    'created_at',
    '-created_at',
    'due_at',
    '-due_at',
    'owner',
    '-owner',
    'product',
    '-product',
    'risk',
    '-risk',
    'status',
    '-status',
    'title',
    '-title',
    'code',
    '-code',
  ]),
  desc: z.boolean().optional(),
});

export type EventSortParam = z.infer<typeof EventSortParam>;

export const EventSortParams = z.array(EventSortParam);

export type EventSortParams = z.infer<typeof EventSortParams>;

export const eventSortParamsValidator = (params: unknown): params is EventSortParams => {
  return EventSortParams.safeParse(params).success;
};
