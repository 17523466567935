import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { QBox, QHeading, QStack, useCurrentUser } from '@qualio/ui-components';
import { ProductList, ProductCreateModal } from '../../components';
import productsApi from '../../api/products.api';
import { CreateProduct, Product } from '../../types/product';
import ProductUpdateModal from '../../components/ProductModal/ProductUpdateModal';
import ProductDeleteModal from '../../components/ProductModal/ProductDeleteModal';
import AddProductButton from '../../components/ProductList/components/AddProductButton';
import { useDocTitle } from '../../hooks';

const ProductsListOverview = () => {
  const { companyId } = useCurrentUser();
  const { data, refetch, isLoading } = useQuery('productsList', () => productsApi.getAllProducts(companyId, {}));
  const { data: statsData } = useQuery('productStats', () => productsApi.getAllProductsStats(companyId));

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<Product>();

  useDocTitle('Quality Events - Products - Qualio');

  const handleAddProduct = useCallback(
    async (payload: CreateProduct) => {
      await productsApi.create(companyId, payload);
      refetch();
    },
    [companyId, refetch],
  );

  const handleUpdateProduct = useCallback(
    async (product: Product) => {
      await productsApi.update(companyId, product);
      refetch();
    },
    [companyId, refetch],
  );

  const handleDeleteProduct = useCallback(
    async (product: Product) => {
      await productsApi.delete(companyId, product.id);
      refetch();
    },
    [companyId, refetch],
  );

  const handleEditProductClick = useCallback((product: Product) => {
    setCurrentProduct(product);
    setIsUpdateModalOpen(true);
  }, []);

  const handleDeleteProductClick = useCallback((product: Product) => {
    setCurrentProduct(product);
    setIsDeleteModalOpen(true);
  }, []);

  return (
    <>
      <QStack direction="column" spacing={6}>
        <QBox display="flex" flexDirection="row" justifyContent="space-between">
          <QHeading size="lg">Products</QHeading>
          <AddProductButton onClickHandler={() => setIsAddModalOpen(true)} />
        </QBox>
        <ProductList
          products={data}
          stats={statsData}
          isLoading={isLoading}
          onEditProduct={handleEditProductClick}
          onDeleteProduct={handleDeleteProductClick}
        />
      </QStack>
      <ProductCreateModal isOpen={isAddModalOpen} setIsOpen={setIsAddModalOpen} onSave={handleAddProduct} />
      {currentProduct && (
        <ProductUpdateModal
          isOpen={isUpdateModalOpen}
          setIsOpen={setIsUpdateModalOpen}
          onSave={handleUpdateProduct}
          product={currentProduct}
        />
      )}
      {currentProduct && (
        <ProductDeleteModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onSave={handleDeleteProduct}
          product={currentProduct}
        />
      )}
    </>
  );
};

export default ProductsListOverview;
