import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { QBox, QSpinner, QMenuButton, QMenuItemType, QText, useCurrentUser, deprecated } from '@qualio/ui-components';
import { UserTask } from '../../../types';
import { TaskStatusTag, DueColumn } from '../../../components';
import { canCompleteTaskFn } from '../../../context';

export type SortByType = { id: string; desc?: boolean }[];

type StepTasksListProps = {
  tasks: UserTask[];
  isLoading?: boolean;
  setIsCompleteModalOpen: (status: boolean) => void;
  setSelectedTask: (task: UserTask) => void;
  setIsDrawerOpen: (status: boolean) => void;
  userTZ: string;
  paginationCallback: (pageIndex: number, pageSize: number) => void;
  sortByCallback: (sortBy: SortByType) => void;
  pageCount: number;
  initialPageIndex: number;
  initialPageSize: number;
  isEventOwner: boolean;
  sortByColumn: SortByType;
};

const StepTasksList = ({
  tasks,
  isLoading,
  setSelectedTask,
  setIsCompleteModalOpen,
  setIsDrawerOpen,
  userTZ,
  paginationCallback,
  sortByCallback,
  pageCount,
  initialPageIndex,
  initialPageSize,
  isEventOwner,
  sortByColumn,
}: StepTasksListProps) => {
  const { userId } = useCurrentUser();
  const today = DateTime.now();
  const zone = useMemo(() => ({ zone: userTZ }), [userTZ]);
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Task name',
        accessor: 'title',
        width: '43%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '12%',
        Cell: (props: any) => <TaskStatusTag status={props.value} />,
      },
      {
        Header: 'Assigned to',
        accessor: 'owner',
        width: '30%',
      },
      {
        Header: 'Due',
        accessor: 'due',
        width: '15%',
        disableSortBy: true,
        Cell: (props: any) => (
          <DueColumn
            referenceDatetime={today}
            createdAtDatetime={DateTime.fromSeconds(props.row.original.taskCreatedAt, zone)}
            timeLimit={props.row.original.taskTimelimit}
            closedAtDatetime={
              props.row.original.taskClosedAtDatetime
                ? DateTime.fromSeconds(props.row.original.taskClosedAtDatetime, zone)
                : undefined
            }
            isClosed={['closed_success', 'closed_fail'].includes(props.row.original.status)}
          />
        ),
      },
      { accessor: 'actions', width: '5%' },
    ],
    [today, zone],
  );

  const handleClickRowMenuItem = useCallback(
    (action: string, task: UserTask) => {
      setSelectedTask(task);
      if (action === 'complete') {
        setIsCompleteModalOpen(true);
      }
    },
    [setIsCompleteModalOpen, setSelectedTask],
  );

  const rowMenu = useCallback(
    (task: UserTask) => {
      const items: QMenuItemType[] = [];
      const canCompleteTask = canCompleteTaskFn(userId, task, isEventOwner);
      const isTaskOpen = task.status === 'open';
      if (isTaskOpen && canCompleteTask) {
        items.push({
          id: 'complete',
          label: 'Complete',
        });
      }

      return (
        <QBox hidden={items.length === 0}>
          <QMenuButton
            buttonLabel="TaskActions"
            variant="icon"
            itemSize="sm"
            iconName="MoreVertical"
            items={items}
            onItemClick={(item: QMenuItemType) => handleClickRowMenuItem(item.id, task)}
          />
        </QBox>
      );
    },
    [handleClickRowMenuItem, isEventOwner, userId],
  );

  const taskTitleCell = useCallback(
    (task: UserTask) => {
      return (
        <QText
          onClick={() => {
            setSelectedTask(task);
            setIsDrawerOpen(true);
          }}
        >
          {task.title || ''}
        </QText>
      );
    },
    [setIsDrawerOpen, setSelectedTask],
  );

  const mappedData = useMemo(
    () =>
      tasks?.map((task: UserTask) => ({
        id: task.id,
        title: taskTitleCell(task),
        status: task.status,
        owner: task.owner || '',
        due: DateTime.fromSeconds(task.created_at, zone).plus({ days: task.time_limit }).toMillis(),
        taskCreatedAt: task.created_at,
        taskTimelimit: task.time_limit,
        taskClosedAtDatetime: task.closed_at || '',
        actions: rowMenu(task),
      })),
    [tasks, rowMenu, taskTitleCell, zone],
  );

  return (
    <QBox p={2}>
      {isLoading ? (
        <QBox w="100%" textAlign="center" p={5}>
          <QSpinner />
        </QBox>
      ) : (
        <deprecated.QDataTable
          columns={COLUMNS}
          data={mappedData}
          hasPageSizeOptions={false}
          manualPagination={{
            paginationCallback,
            pageCount,
            initialPageIndex,
            initialPageSize,
          }}
          manualSortBy={{ sortByCallback, defaultSortByColumn: sortByColumn }}
        />
      )}
    </QBox>
  );
};

export default StepTasksList;
