import React from 'react';
import { CurrentUserContextType, QMenuButton, QMenuItem } from '@qualio/ui-components';
import { useArchiveTemplate, useRestoreTemplate } from './hooks';

type OtherTempProps = {
  templateId: string;
  templateActive: boolean;
  companyId: CurrentUserContextType['companyId'];
};

const TemplateRowActions = ({ templateId, templateActive, companyId }: OtherTempProps) => {
  const { mutate: archiveTemplate } = useArchiveTemplate(companyId);
  const { mutate: restoreTemplate } = useRestoreTemplate(companyId);

  return (
    <QMenuButton buttonLabel="user actions" iconName="MoreVertical" itemSize="sm" variant="icon">
      {templateActive ? (
        <QMenuItem data-cy={`archive-template-${templateId}`} onClick={() => archiveTemplate(templateId)}>
          Archive
        </QMenuItem>
      ) : (
        <QMenuItem data-cy={`restore-template-${templateId}`} onClick={() => restoreTemplate(templateId)}>
          Restore
        </QMenuItem>
      )}
    </QMenuButton>
  );
};

export default TemplateRowActions;
