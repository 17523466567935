import React from 'react';
import { QFormControl, QInput, QStack, QTextarea } from '@qualio/ui-components';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { DigitalSignature } from '../../../types/digitalSignature';

type Props = {
  formMethods: UseFormReturn<DigitalSignature>;
  onSubmit: any;
  isCommentRequired: boolean;
};

const DigitalSignatureForm: React.FC<Props> = ({ formMethods, onSubmit, isCommentRequired }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  if (!isCommentRequired) {
    setValue('comment', '');
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QStack direction="column" spacing={4}>
          <QFormControl label="Email" error={errors.email?.message} isInvalid={!!errors.email} isRequired>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
            />
          </QFormControl>
          <QFormControl label="Password" error={errors.password?.message} isInvalid={!!errors.password} isRequired>
            <Controller
              control={control}
              name="password"
              render={({ field: { ref: _ref, ...field } }) => <QInput role="textbox" type="password" {...field} />}
            />
          </QFormControl>
          <QFormControl
            label="Comment"
            error={errors.comment?.message}
            isInvalid={isCommentRequired && !!errors.comment}
            isRequired={isCommentRequired}
          >
            <Controller
              control={control}
              name="comment"
              render={({ field: { ref: _ref, ...field } }) => <QTextarea {...field} />}
            />
          </QFormControl>
        </QStack>
      </form>
    </FormProvider>
  );
};

export default DigitalSignatureForm;
