import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { QBox, QDrawer } from '@qualio/ui-components';
import EventFormPreview from '../../EventFormPreview/EventFormPreview';
import { FormEditorForm } from '../shared';
import { addUniqueIdAttribute } from '../../../utils/objectUtils';

type PreviewFormTemplateProps = {
  title: string;
  onClose: any;
};

const PreviewFormTemplate: React.FC<PreviewFormTemplateProps> = ({ title, onClose }) => {
  const { getValues } = useFormContext<FormEditorForm>();
  const formFields = getValues('fields');
  const mappedFields = useMemo(() => formFields.map(addUniqueIdAttribute), [formFields]);

  return (
    <QDrawer isOpen={true} onClose={onClose} size="xl" title={title}>
      <QBox data-cy={'preview-form-drawer'} p={1} mt="3">
        <EventFormPreview formFields={mappedFields} />
      </QBox>
    </QDrawer>
  );
};

export default PreviewFormTemplate;
