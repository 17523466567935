import React, { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { QBox, QCenter, QSpinner, useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useFormDetails, BaseQueryKey as FormDetailsBaseQueryKey } from '../../hooks/useEventForm';
import EventFormHeader from './components/EventFormHeader';
import { useGetUserTimezone } from '../EventTemplate/hooks';
import formsApi from '../../api/forms.api';
import { Approver, ApproverType, UpdateApproversPayload } from '../../types';
import EventFormBody from './components/EventFormBody/EventFormBody';
import { EventPermissionsProvider } from '../../context';
import { useEventDetails, useEventFormEdit } from '../../hooks';
import ApproversModal from './components/ApproversModal';
import usersApi from '../../api/users.api';
import approversApi from '../../api/approvers.api';
import * as DisplayStrings from '../../displayStrings';
import EventFormDetails from './components/EventFormDetails';
import AssignFormModal from './components/AssignFormModal';
import { extractMessageFromError } from '../../utils/errorUtils';
import { CreateFieldValue } from '../../types/formFields/forms';

type EventStepFormProps = {
  viewType: 'view' | 'edit';
};
const EventStepForm: React.FC<EventStepFormProps> = ({ viewType }) => {
  const [approverType, setApproverType] = useState<ApproverType>('approvers');
  const [isApproverModalOpen, setIsApproverModalOpen] = useState<boolean>(false);
  const [isAssignFormModalOpen, setIsAssignFormModalOpen] = useState<boolean>(false);

  const { eventId: rawEventId, stepId: rawStepId, formId: rawFormId } = useParams();

  const eventId = Number(rawEventId);
  const stepId = Number(rawStepId);
  const formId = Number(rawFormId);

  const { companyId, userId } = useCurrentUser();
  const queryClient = useQueryClient();

  const { data: currentUserData } = useQuery(['currentUser', userId, companyId], () => usersApi.getUser(userId));
  const userCompanyId = currentUserData?.companies.find((company) => company.id === companyId)?.usercompany_id;

  const { data: usersData } = useQuery(['usersList', companyId], () =>
    usersApi.getAllUsers(companyId, { status: 'accepted' }),
  );
  const validReviewersList = useMemo(
    () =>
      usersData?.filter(
        (u) => u.permissions.includes('feature_enabled_quality_events') && u.permissions.includes('can_work_on_issue'),
      ),
    [usersData],
  );
  const validApproversList = useMemo(
    () => validReviewersList?.filter((u) => u.usergroups?.some((role) => ['normal', 'quality'].includes(role))),
    [validReviewersList],
  );

  const getUserCompanyId = ({ usercompany_id }: Approver) => usercompany_id;

  const reviewersQuery = useQuery(['reviewersList', formId], () =>
    approversApi.getApprovers(companyId, formId, 'reviewers'),
  );
  const approversQuery = useQuery(['approversList', formId], () =>
    approversApi.getApprovers(companyId, formId, 'approvers'),
  );

  const formQuery = useFormDetails(eventId, stepId, formId);
  const eventQuery = useEventDetails(eventId);
  const timeZoneQuery = useGetUserTimezone(userId, companyId);
  const { showToast } = useToastProvider();
  const navigate = useNavigate();
  const { mutate: updateForm } = useMutation(
    (values: CreateFieldValue[]) => formsApi.updateFormValues(companyId, eventId, stepId, formId, values),
    {
      onSuccess: async () => {
        showToast({
          title: DisplayStrings.FormUpdateSuccessTitle,
          description: DisplayStrings.FormUpdateSuccessMessage,
          status: 'success',
        });
        await queryClient.invalidateQueries({ queryKey: [FormDetailsBaseQueryKey, formId] });
        navigate(`/events/${eventId}/steps/${stepId}/forms/${formId}`);
      },
      onError: (e: AxiosError) => {
        showToast({
          title: DisplayStrings.FormUpdateFailedTitle,
          description: extractMessageFromError(e),
          status: 'error',
        });
      },
    },
  );

  const updateApproversMutate = useMutation((data: UpdateApproversPayload) =>
    approversApi.updateApprovers(
      companyId,
      eventId,
      stepId,
      formId,
      data.approversToRemove,
      data.approversToAdd,
      data.userType,
      data.removeComment,
    ),
  );

  const { editEventFormMutate } = useEventFormEdit(eventId, stepId, formId, () => {
    formQuery.refetch();
  });

  if (timeZoneQuery.isError) {
    throw new Error(DisplayStrings.TimeZoneMissingMessage);
  }

  if (formQuery.isError) {
    throw new Error(DisplayStrings.FormMissingMessage);
  }

  if (eventQuery.isError) {
    throw new Error(DisplayStrings.EventMissingMessage);
  }

  const formIsReady = formQuery.isFetchedAfterMount && !formQuery.isLoading && !formQuery.isIdle;
  const approversReady = approversQuery.isFetched;
  const reviewersReady = reviewersQuery.isFetched;
  const tzReady = timeZoneQuery.data;
  const eventReady = eventQuery.data;

  if (!formIsReady || !tzReady || !eventReady || !approversReady || !reviewersReady) {
    return (
      <QCenter pt={10}>
        <QSpinner />
      </QCenter>
    );
  }

  const form = formQuery.data;
  const eventData = eventQuery.data;

  const isEditView = viewType === 'edit' && form.status === 'draft';

  const handleUpdateForm = (values: CreateFieldValue[], reviewers: number[], approvers: number[]) => {
    updateApproversOrReviewers(reviewers, 'reviewers', false);
    updateApproversOrReviewers(approvers, 'approvers', false);
    updateForm(values);
  };

  const updateApproversOrReviewers = (usercompany_ids: number[], userType: ApproverType, showSuccessToast = true) => {
    if (usercompany_ids.length === 0 && ['for_review'].includes(form.status) && userType === 'reviewers') {
      showToast({
        title: DisplayStrings.ApproversUpdateFailedTitle(userType),
        description: DisplayStrings.AtLeastOneAssigneeRequiredMessage(form.status),
        status: 'error',
      });
      return;
    }
    const query = userType === 'approvers' ? approversQuery : reviewersQuery;
    const existing = query.data?.map(getUserCompanyId) ?? [];
    const toAdd = usercompany_ids.filter((uc) => !existing?.includes(uc));
    const toRemove = existing.filter((uc) => !usercompany_ids?.includes(uc));

    if (toAdd.length === 0 && toRemove.length === 0) {
      return;
    }

    updateApproversMutate
      .mutateAsync({ approversToAdd: toAdd, approversToRemove: toRemove, userType: userType })
      .then(() => {
        if (showSuccessToast) {
          showToast({
            title: DisplayStrings.ApproversUpdateSuccessTitle(userType),
            description: DisplayStrings.ApproversUpdateSuccessMessage(userType),
            status: 'success',
          });
        }
      })
      .catch((error: AxiosError) => {
        const errorMessage = extractMessageFromError(error);
        const toastMessage = errorMessage.includes('remove already actioned approver')
          ? DisplayStrings.RemovingActionedApproverErrorMessage(userType)
          : DisplayStrings.ApproversUpdateFailedMessage(userType);
        showToast({
          title: DisplayStrings.ApproversUpdateFailedTitle(userType),
          description: toastMessage,
          status: 'error',
        });
      })
      .finally(() => {
        query.refetch().then(() => {
          setIsApproverModalOpen(false);
        });
      });
  };

  const reassignFormAction = (newOwnerId: string) => {
    editEventFormMutate(
      { owner_id: Number(newOwnerId) },
      {
        onSuccess: () => {
          setIsAssignFormModalOpen(false);
          showToast({
            title: 'User reassigned',
            description: 'User reassigned successfully.',
            status: 'success',
          });
        },
      },
    );
  };

  const closeApproversModal = () => setIsApproverModalOpen(false);
  const existingApprovers = approversQuery.data ?? [];
  const existingReviewers = reviewersQuery.data ?? [];

  return (
    <EventPermissionsProvider userId={userId} event={eventData!.issue}>
      <QBox data-cy={'event-step-form-screen'}>
        <EventFormHeader
          eventId={Number(eventId)}
          form={form}
          assignedApprovers={existingApprovers}
          assignedReviewers={existingReviewers}
          editMode={isEditView}
          formRefetch={formQuery.refetch}
          setApproverType={setApproverType}
          setIsApproverModalOpen={setIsApproverModalOpen}
          setIsAssignFormModalOpen={setIsAssignFormModalOpen}
          currentUserCompanyId={userCompanyId}
        />
        {isEditView ? (
          <EventFormBody
            onSubmit={handleUpdateForm}
            form={form}
            event={eventData!.issue}
            validApproversList={validApproversList ?? []}
            validReviewersList={validReviewersList ?? []}
            existingApprovers={existingApprovers.map(getUserCompanyId)}
            existingReviewers={existingReviewers.map(getUserCompanyId)}
          />
        ) : (
          <EventFormDetails
            form={form}
            event={eventData!.issue}
            reviewers={existingReviewers}
            approvers={existingApprovers}
          />
        )}
        <ApproversModal
          isOpen={isApproverModalOpen}
          onSubmit={updateApproversOrReviewers}
          onClose={closeApproversModal}
          validApproversList={validApproversList ?? []}
          validReviewersList={validReviewersList ?? []}
          existingApprovers={existingApprovers.map(getUserCompanyId)}
          existingReviewers={existingReviewers.map(getUserCompanyId)}
          approversType={approverType}
        />
        <AssignFormModal
          isOpen={isAssignFormModalOpen}
          setIsOpen={setIsAssignFormModalOpen}
          onSave={reassignFormAction}
          users={validReviewersList ?? []}
          reassign={true}
          currentAssignedUserName={form.owner_full_name ?? ''}
        />
      </QBox>
    </EventPermissionsProvider>
  );
};

export default EventStepForm;
