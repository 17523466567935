import React from 'react';
import { useCurrentUser, QSpinner } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';
import { EventTemplateFieldAttributeNames, EventTemplateFormValues } from '../../types';
import EventTemplateForm from './EventTemplateForm';
import { useGetUserTimezone, useTemplateQuery } from './hooks';
import { getBlankStep } from '../../utils/eventTemplateUtils';
import { useDocTitle } from '../../hooks';

type EventTemplateProps = {
  viewType: 'new' | 'edit';
};

const EventTemplate: React.FC<EventTemplateProps> = ({ viewType }) => {
  const { companyId, userId } = useCurrentUser();
  const { eventTemplateId } = useParams();
  const isEditView = viewType === 'edit';

  const defaultFormValues: EventTemplateFormValues = {
    name: '',
    prefix: '',
    default_time_limit: 28,
    start_time_constraint: 0,
    fields: {
      [EventTemplateFieldAttributeNames.Enum.product]: {
        attribute_name: EventTemplateFieldAttributeNames.Enum.product,
        mandatory: false,
      },
      [EventTemplateFieldAttributeNames.Enum.root_cause]: {
        attribute_name: EventTemplateFieldAttributeNames.Enum.root_cause,
        mandatory: false,
      },
      [EventTemplateFieldAttributeNames.Enum.risk]: {
        attribute_name: EventTemplateFieldAttributeNames.Enum.risk,
        mandatory: true,
      },
      [EventTemplateFieldAttributeNames.Enum.supplier]: {
        attribute_name: EventTemplateFieldAttributeNames.Enum.supplier,
        mandatory: false,
      },
    },
    steps: [
      {
        ...getBlankStep(),
        order: 1,
        label: 'Resolution',
        type: 'document',
      },
    ],
  };

  const templateQuery = useTemplateQuery(companyId, eventTemplateId, isEditView);
  const timeZoneQuery = useGetUserTimezone(userId, companyId);

  useDocTitle(isEditView ? `${templateQuery.data?.name} - Event templates - Qualio` : 'Event templates - Qualio');

  // When in edit mode we need to wait for template to load after mount, otherwise we can render immediately
  const templateIsLoading = isEditView ? templateQuery.isLoading || !templateQuery.isFetchedAfterMount : false;

  if (templateIsLoading || timeZoneQuery.isLoading || timeZoneQuery.isIdle) {
    return <QSpinner />;
  }

  if (templateQuery.isError || timeZoneQuery.isError) {
    throw new Error('Could not find event template or user timezone');
  }

  // templateQuery.data should always exist here if we are in edit view and aren't loading, but TS doesn't know that
  const data: EventTemplateFormValues = isEditView && templateQuery.data ? templateQuery.data : defaultFormValues;

  return (
    <EventTemplateForm
      initialValues={data}
      companyId={companyId}
      templateId={eventTemplateId ? Number(eventTemplateId) : undefined}
    />
  );
};

export default EventTemplate;
