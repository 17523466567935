import React, { useMemo } from 'react';
import { QMenuButton, QMenuItemType } from '@qualio/ui-components';
import { useEventPermissions } from '../../../../hooks';

type menuProps = {
  eventStatus: string;
  eventId: number;
  onEditEvent: (eventId: number) => void;
  onChangeOwner: (eventId: number) => void;
  onEscalateEvent: (eventId: number) => void;
  onCloseEvent: (eventId: number) => void;
  onReopen: (eventId: number) => void;
  onOpen: (eventId: number) => void;
  onUpdateEventProperties: (eventId: number) => void;
};

const menuItems = {
  edit: {
    id: 'edit',
    label: 'Edit',
  },
  changeOwner: {
    id: 'changeOwner',
    label: 'Change owner',
  },
  escalateEvent: {
    id: 'escalateEvent',
    label: 'Escalate and create new event',
  },
  close: {
    id: 'close',
    label: 'Close',
  },
  updateEventProperties: {
    id: 'updateEventProperties',
    label: 'Update event properties',
  },
  reopen: {
    id: 'reopen',
    label: 'Reopen',
  },
  open: {
    id: 'open',
    label: 'Open',
  },
};

const KebabMenu: React.FC<menuProps> = ({
  eventStatus,
  eventId,
  onEditEvent,
  onChangeOwner,
  onEscalateEvent,
  onCloseEvent,
  onReopen,
  onOpen,
  onUpdateEventProperties,
}) => {
  const { canModifyEvent, canChangeOwner, canReopenEvent, canCloseEventEarly, canOpenImportedEvent } =
    useEventPermissions();

  const handleMenuClick = (item: QMenuItemType) => {
    if (item.id === 'edit') {
      onEditEvent(eventId);
    }
    if (item.id === 'changeOwner') {
      onChangeOwner(eventId);
    }
    if (item.id === 'escalateEvent') {
      onEscalateEvent(eventId);
    }
    if (item.id === 'close') {
      onCloseEvent(eventId);
    }
    if (item.id === 'reopen') {
      onReopen(eventId);
    }
    if (item.id === 'open') {
      onOpen(eventId);
    }
    if (item.id === 'updateEventProperties') {
      onUpdateEventProperties(eventId);
    }
  };

  const renderMenuItems = useMemo(() => {
    const isStatus = (expected: string[]) => expected.includes(eventStatus);
    const items = [];
    if (canChangeOwner) {
      items.push(menuItems.changeOwner);
    }
    if (canOpenImportedEvent && isStatus(['imported'])) {
      items.push(menuItems.open);
    }
    if (canModifyEvent && isStatus(['open'])) {
      items.push(menuItems.edit);
    }
    if (canModifyEvent) {
      items.push(menuItems.escalateEvent);
    }
    if (canCloseEventEarly && isStatus(['imported', 'open'])) {
      items.push(menuItems.close);
    }
    if (canReopenEvent && isStatus(['closed'])) {
      items.push(menuItems.reopen);
    }
    if (canModifyEvent && isStatus(['closed'])) {
      items.push(menuItems.updateEventProperties);
    }
    return items;
  }, [canChangeOwner, canOpenImportedEvent, canCloseEventEarly, canModifyEvent, canReopenEvent, eventStatus]);

  return (
    <>
      {renderMenuItems.length > 0 && (
        <QMenuButton
          buttonLabel="Options"
          iconName="MoreVertical"
          itemSize="sm"
          items={renderMenuItems}
          onItemClick={handleMenuClick}
          variant="icon"
        />
      )}
    </>
  );
};

export default KebabMenu;
