import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { QStack, QTabHeader, QTabs, useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { EventNewModal, QualityEventListFilters, TaskList, TaskListFilters } from '../../components';
import { default as QualityEventList } from '../../components/QualityEventList/QualityEventList';
import { QualityEventsGet, EventSortParam, TasksRequest, eventSortParamsValidator } from '../../types';
import eventMetadataApi from '../../api/eventMetadata.api';
import productsApi from '../../api/products.api';
import eventTypesApi from '../../api/eventTypes.api';
import rootCausesApi from '../../api/rootCauses.api';
import usersApi from '../../api/users.api';
import tagsApi from '../../api/tags.api';
import tasksApi from '../../api/tasks.api';
import ExportUtil from '../../utils/exportUtils';
import { filterTags } from '../../utils/eventUtils';
import { getUrlParameter } from '../../utils/searchParamUtils';
import { useDocTitle, useEventsList } from '../../hooks';
import QualityEventListHeader from './QualityEventListHeader/QualityEventListHeader';
import { DefaultErrorMessage } from '../../displayStrings';

const defaultPageSize = 10;
const allowedPageSizes = [10, 25, 50, 100];
const defaultSortField = 'created_at';

const QualityEventListOverview: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState<QualityEventsGet | undefined>(undefined);
  const [tasksFilterParams, setTasksFilterParams] = useState<TasksRequest | undefined>(undefined);

  const getInitialPageNumber = () => {
    const pageNumberFromUrl = Number(getUrlParameter(searchParams, 'page_number'));
    return pageNumberFromUrl > 0 ? pageNumberFromUrl - 1 : 0;
  };

  const getInitialPageSize = () => {
    const pageSizeFromUrl = Number(getUrlParameter(searchParams, 'page_size'));
    if (allowedPageSizes.includes(pageSizeFromUrl)) {
      return pageSizeFromUrl;
    }
    return defaultPageSize;
  };

  const [eventsPageNumber, setEventsPageNumber] = useState(getInitialPageNumber());
  const [eventsPageSize, setEventsPageSize] = useState(getInitialPageSize());
  const [eventsPageCount, setEventsPageCount] = useState(0);
  const [eventTotalItems, setEventTotalItems] = useState(0);
  const [tasksPageNumber, setTasksPageNumber] = useState(getInitialPageNumber());
  const [tasksPageSize, setTasksPageSize] = useState(getInitialPageSize());
  const [tasksPageCount, setTasksPageCount] = useState(0);
  const [tasksTotalItems, setTasksTotalItems] = useState(0);
  const [eventsSortBy, setEventsSortBy] = useState<string | undefined>(
    getUrlParameter(searchParams, 'order_by') || '-' + defaultSortField,
  );
  const [sortByColumn, setSortByColumn] = useState<EventSortParam[]>([{ id: defaultSortField, desc: true }]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(!!searchParams.get('open_modal'));
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'events');

  const { companyId, userId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const exportUtil = useMemo(() => new ExportUtil(showToast), [showToast]);

  const handleErr = (name: string) => {
    return {
      onError: () => {
        showToast({
          title: `Could not retrieve ${name}`,
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    };
  };

  const productsQueryResult = useQuery(
    'productsList',
    () => productsApi.getAllProducts(companyId, {}),
    handleErr('products'),
  );
  const eventTypesQueryResult = useQuery(
    'eventTypesList',
    () => eventTypesApi.getAllEventTypes(companyId, true),
    handleErr('event types'),
  );
  const rootCausesQueryResult = useQuery(
    'rootCausesList',
    () => rootCausesApi.getAllRootCauses(companyId, {}),
    handleErr('root causes'),
  );
  const usersQueryResult = useQuery(
    'usersList',
    () => usersApi.getAllUsers(companyId, { status: 'accepted' }),
    handleErr('users'),
  );
  const tagsQueryResult = useQuery('tagsList', () => tagsApi.getAllTags(companyId), handleErr('tags'));
  const eventMetadataQueryResult = useQuery(
    'eventMetadata',
    () => eventMetadataApi.getMetadata(companyId),
    handleErr('event metadata'),
  );
  const refSetSortBy = useRef<((columnAccessor: string, isSortedDesc: boolean | undefined) => void) | undefined>(
    undefined,
  );

  const showTasks = activeTab === 'tasks';
  const showEvents = activeTab === 'events';

  const queryClient = useQueryClient();

  const { eventsListData, isEventsListLoading, isEventsListFetching, refetchEvents } = useEventsList(
    companyId,
    filterParams,
    (data) => {
      if (data) {
        setEventsPageCount(data.meta.page.total_pages);
        setEventTotalItems(data.meta.page.total_items);
      }
    },
  );

  const {
    data: tasksListData,
    isLoading: isTasksListLoading,
    isFetching: isTasksListFetching,
    refetch: refetchTasks,
  } = useQuery('tasksList', () => tasksApi.getAllTasks(companyId, tasksFilterParams), {
    enabled: false,
    onSuccess: (data) => {
      if (data) {
        setTasksPageCount(data.meta.page.total_pages);
        setTasksTotalItems(data.meta.page.total_items);
      }
    },
  });

  useEffect(() => {
    if (showEvents && filterParams) {
      queryClient.cancelQueries('eventsList');
      refetchEvents();
    }
  }, [filterParams, refetchEvents, showEvents, queryClient]);

  useEffect(() => {
    if (showTasks && tasksFilterParams) {
      queryClient.cancelQueries('tasksList');
      refetchTasks();
    }
  }, [tasksFilterParams, refetchTasks, showTasks, queryClient]);

  useDocTitle(showEvents ? 'Quality Events - Qualio' : 'Quality Events - Tasks - Qualio');

  const handleChangeTab = (header: QTabHeader) => {
    if (header.id === 'tab-events') {
      setSearchParams({ ...searchParams, tab: 'events' });
      setActiveTab('events');
    }
    if (header.id === 'tab-tasks') {
      setSearchParams({ ...searchParams, tab: 'tasks' });
      setActiveTab('tasks');
    }
  };

  const paginationCallback = useCallback(
    (pageIndex: number, pageSize: number) => {
      setEventsPageNumber(pageIndex);
      setEventsPageSize(pageSize);
    },
    [setEventsPageNumber, setEventsPageSize],
  );

  const taskPaginationCallback = useCallback(
    (pageIndex: number, pageSize: number) => {
      setTasksPageNumber(pageIndex);
      setTasksPageSize(pageSize);
    },
    [setTasksPageNumber, setTasksPageSize],
  );

  const sortByCallback = useCallback(
    (sortParams: unknown) => {
      if (!eventSortParamsValidator(sortParams)) {
        return;
      }
      if (sortParams.length === 0) {
        setSortByColumn(sortParams);
        setEventsSortBy(undefined);
        return;
      }
      const sortBy = sortParams[0];
      const sortByField = sortBy === undefined ? undefined : (sortBy.desc ? '-' : '') + sortBy.id;
      setSortByColumn(sortParams);
      setEventsSortBy(sortByField);
    },
    [setSortByColumn, setEventsSortBy],
  );

  const onTextSearch = useCallback(
    (term: string | undefined, prevTerm: string | undefined) => {
      if (!refSetSortBy.current) {
        return;
      }
      if (term && !prevTerm && sortByColumn && sortByColumn.length > 0) {
        refSetSortBy.current(sortByColumn[0]!.id, undefined);
        sortByColumn.length = 0;
        setEventsSortBy(undefined);
      } else if (!term && prevTerm && sortByColumn.length === 0) {
        refSetSortBy.current(defaultSortField, false);
        sortByColumn.length = 0;
        sortByColumn.push({ id: defaultSortField, desc: true });
        setEventsSortBy('-' + defaultSortField);
      }
    },
    [sortByColumn],
  );

  const filteredTagsData = filterTags(userId, usersQueryResult.data || [], tagsQueryResult.data || []);

  return (
    <>
      <QStack direction="column" spacing={6}>
        <QualityEventListHeader setIsModalOpen={setIsModalOpen}></QualityEventListHeader>
        <QTabs
          tabs={[
            {
              id: 'tab-events',
              title: 'Events',
            },
            {
              id: 'tab-tasks',
              title: 'Tasks',
            },
          ]}
          onClick={handleChangeTab}
          activeTabId={`tab-${activeTab}`}
          data-metrics="events-overview-navigation-tabs"
        />

        {showEvents && (
          <>
            <QualityEventListFilters
              setFilterParamsFn={setFilterParams}
              pageSize={eventsPageSize}
              pageNumber={eventsPageNumber}
              setEventsPageNumber={setEventsPageNumber}
              sortBy={eventsSortBy}
              exportUtil={exportUtil}
              products={productsQueryResult.data}
              eventTypes={eventTypesQueryResult.data}
              rootCauses={rootCausesQueryResult.data}
              users={usersQueryResult.data}
              tags={filteredTagsData}
              onTextSearch={onTextSearch}
            />
            <QualityEventList
              qualityEvents={eventsListData?.data}
              isLoading={isEventsListLoading || isEventsListFetching}
              pageCount={eventsPageCount}
              totalItems={eventTotalItems}
              paginationCallback={paginationCallback}
              sortByCallback={sortByCallback}
              initialPageIndex={eventsPageNumber}
              initialPageSize={eventsPageSize}
              sortByColumn={sortByColumn}
              refSetSortBy={refSetSortBy}
            />{' '}
          </>
        )}
        {showTasks && (
          <>
            <TaskListFilters
              setFilterParamsFn={setTasksFilterParams}
              pageSize={tasksPageSize}
              pageNumber={tasksPageNumber}
              setTasksPageNumber={setTasksPageNumber}
              sortBy={eventsSortBy || '-' + defaultSortField}
              eventTypes={eventTypesQueryResult.data}
              users={usersQueryResult.data}
            />
            <TaskList
              tasks={tasksListData?.data}
              isLoading={isTasksListLoading || isTasksListFetching}
              pageCount={tasksPageCount}
              totalItems={tasksTotalItems}
              paginationCallback={taskPaginationCallback}
              sortByCallback={sortByCallback}
              initialPageIndex={tasksPageNumber}
              initialPageSize={tasksPageSize}
              sortByColumn={sortByColumn}
            />
          </>
        )}
      </QStack>
      {isModalOpen ? (
        <EventNewModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          selectsData={{
            eventTypes: eventTypesQueryResult.data || [],
            eventTags: filteredTagsData,
            probabilities: eventMetadataQueryResult.data?.probabilities || [],
            severities: eventMetadataQueryResult.data?.severities || [],
            risks: eventMetadataQueryResult.data?.risks || [],
            users: usersQueryResult.data || [],
            products: productsQueryResult.data || [],
            rootCauses: rootCausesQueryResult.data || [],
          }}
          refetchEvents={refetchEvents}
        />
      ) : null}
    </>
  );
};

export default QualityEventListOverview;
