import { v4 } from 'uuid';

export const addUniqueIdAttribute = <T extends Record<string, unknown>>(obj: T) => {
  const uniqueId = obj.id && (typeof obj.id === 'string' || typeof obj.id === 'number') ? obj.id : v4();

  return {
    ...obj,
    uniqueId,
  };
};
