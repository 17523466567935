import React from 'react';
import exportsApi from '../api/exports.api';
import { QToastProps } from '@qualio/ui-components';
import { wait } from './asyncUtils';

export interface ExportUtilDefinition {
  showToast: (options: QToastProps) => void;
  displayExportComplete(payload: string): void;
  exportStatus(companyId: number, exportsId: string): void;
  displayPreparingForDownload(): void;
  displayExportStatus(companyId: number, exportsId: string): void;
  displayErrorTryingToExport(): void;
}
export default class ExportUtil implements ExportUtilDefinition {
  constructor(public showToast: (options: QToastProps) => void) {}

  displayExportComplete = (payload: string) => {
    this.showToast({
      title: 'File Ready',
      description: (
        <span>
          The file is now available to{' '}
          <a style={{ color: '#0069FF' }} href={payload}>
            download
          </a>
        </span>
      ),
      duration: null,
      status: 'success',
    });
  };

  exportStatus = (companyId: number, exportsId: string) => {
    this.retryWithDelay(companyId, exportsId, 120);
  };

  retryWithDelay = async (companyId: number, exportsId: string, retries: number): Promise<void> => {
    try {
      // try
      const exportStatusResponse = await exportsApi.getExport(companyId, exportsId);
      if (exportStatusResponse?.ready === true) {
        exportStatusResponse?.successful
          ? this.displayExportComplete(exportStatusResponse?.payload as string)
          : this.displayErrorTryingToExport();
        return;
      }
      if (retries <= 0) {
        this.displayErrorTryingToExport();
        return;
      }
      //delay the next call
      await wait(1000);

      return this.retryWithDelay(companyId, exportsId, retries - 1);
    } catch (err) {
      console.log(err);
      this.displayErrorTryingToExport();
    }
  };

  displayPreparingForDownload = () => {
    this.showToast({
      title: 'Preparing file for download',
      description: "We'll let you know when the file is ready",
      duration: 5000,
      status: 'info',
    });
  };

  displayExportStatus = (companyId: number, exportsId: string) => {
    this.displayPreparingForDownload();
    this.exportStatus(companyId, exportsId);
  };

  displayErrorTryingToExport = () => {
    this.showToast({
      title: 'Error',
      description: 'Failed to export the file.',
      duration: 5000,
      status: 'error',
    });
  };
}
