import React, { useEffect } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';

import { QBox, QFormControl, QInput, QSelect, QSelectItem, QStack, QText, QTextarea } from '@qualio/ui-components';

import { useUsersOptions } from '../../../hooks/useUsersOptions';
import {
  EventTag,
  EventTemplateField,
  EventType,
  ProbabilityType,
  Product,
  QualityEvent,
  QualityEventCreate,
  RiskType,
  RootCause,
  SeverityType,
  User,
} from '../../../types';
import { keyToTitle } from '../../../utils/textUtils';

type Props = {
  formMethods: UseFormReturn<QualityEventCreate>;
  onSubmit: any;
  data: {
    eventTypes: EventType[];
    eventTags: EventTag[];
    probabilities: ProbabilityType[];
    severities: SeverityType[];
    risks: RiskType[];
    users: User[];
    products: Product[];
    rootCauses: RootCause[];
  };
  event: QualityEvent;
  userId: number;
  optionalFields: EventTemplateField[] | [];
};

const EventForm: React.FC<Props> = ({ formMethods, onSubmit, data, event, userId, optionalFields }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const eventTypesOptions: QSelectItem[] = data.eventTypes.reduce((options, eventType) => {
    if (eventType.id !== String(event.workflow_id)) {
      options.push({ value: String(eventType.id), label: eventType.name });
    }
    return options;
  }, [] as QSelectItem[]);
  const eventTypeTimeLimit = Object.assign(
    {},
    ...data.eventTypes.map((x) => ({ [String(x.id)]: String(x.defaultTimeLimit) })),
  );
  const usersOptions = useUsersOptions(data.users, 'can_work_on_issue');

  useEffect(() => {
    setValue('owner_id', String(userId));
    setValue('description', `Copied over from ${event.code}: ${event.description}`);
  }, [event.description, event.code, userId, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QStack direction="column" spacing={4}>
          <QFormControl
            label="Event type"
            error={errors.workflow_id?.message}
            isInvalid={!!errors.workflow_id}
            isRequired
            isReadOnly={true}
            data-metrics="event-type-select"
          >
            <Controller
              control={control}
              name="workflow_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QSelect
                  {...field}
                  onChange={(changeObject) => {
                    if (!changeObject) {
                      return;
                    }

                    setValue('time_limit', eventTypeTimeLimit[changeObject.value]);
                    onChange(changeObject.value);
                  }}
                  options={eventTypesOptions}
                />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Title"
            error={errors.title?.message}
            isInvalid={!!errors.title}
            data-metrics="event-title-input"
            isRequired
          >
            <Controller
              control={control}
              name="title"
              render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
            />
          </QFormControl>
          <QFormControl
            label="Owner"
            error={errors.owner_id?.message}
            isInvalid={!!errors.owner_id}
            data-metrics="event-owner-select"
            isRequired
          >
            <Controller
              control={control}
              name="owner_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QSelect
                  {...field}
                  onChange={(changeObject) => {
                    if (changeObject) {
                      onChange(changeObject.value);
                    }
                  }}
                  options={usersOptions}
                />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Time to resolve (days)"
            helper="Period of time within which the event must be resolved."
            data-metrics="event-time-to-resolve-input"
            error={errors.time_limit?.message}
            isInvalid={!!errors.time_limit}
            isRequired
          >
            <Controller
              control={control}
              name="time_limit"
              render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
            />
          </QFormControl>
          <QFormControl label="Description" data-metrics="event-description-input">
            <Controller
              control={control}
              name="description"
              render={({ field: { ref: _ref, ...field } }) => <QTextarea {...field} />}
            />
          </QFormControl>
          <QText>The following event fields will be copied to the new event:</QText>
          <QBox mt={0} pl={8}>
            <ul>
              <li>Tags</li>
              <li>Related documents</li>
              {optionalFields.map((field) => {
                switch (field.attribute_name) {
                  case 'risk':
                    return <li key={field.attribute_name}>Probability</li>;
                  case 'supplier':
                    return <li key={field.attribute_name}>Suppliers</li>;
                  default:
                    return <li key={field.attribute_name}>{keyToTitle(field.attribute_name)}</li>;
                }
              })}
            </ul>
          </QBox>
          <QText>You can edit event fields after creation.</QText>
        </QStack>
      </form>
    </FormProvider>
  );
};

export default EventForm;
