import * as z from 'zod';

export const RegistryCategorySchema = z.object({
  id: z.number(),
  registry_category_matrix_id: z.string(),
  name: z.string(),
  qualio_managed: z.boolean(),
  company_id: z.number().nullable(),
});
export type RegistryCategory = z.infer<typeof RegistryCategorySchema>;

export const RegistryCategoriesResponseSchema = z.object({
  total: z.number(),
  items: z.array(RegistryCategorySchema),
});
