import React, { useEffect } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';

import {
  QFormControl,
  QInput,
  QMultiSelect,
  QSelect,
  QSelectItem,
  QStack,
  QTextarea,
  useCurrentUser,
} from '@qualio/ui-components';

import { quickSearchApiFactory } from '../../../api/quickSearch.api';
import { useUsersOptions } from '../../../hooks/useUsersOptions';
import {
  EventTag,
  EventType,
  ProbabilityType,
  Product,
  QualityEvent,
  QualityEventCreate,
  RiskType,
  RootCause,
  SeverityType,
  User,
} from '../../../types';
import { EventTemplateField } from '../../../types/eventField';
import { Relation } from '../../../types/relation';
import { eventFieldsSetter } from '../../../utils/eventUtils';
import EventFormOptionalFields from './EventFormOptionalFields';
import MultiSelectWithSuggestions from './MultiSelectWithSuggestions';

type Props = {
  formMethods: UseFormReturn<QualityEventCreate>;
  onSubmit: any;
  data: {
    eventTypes: EventType[];
    eventTags: EventTag[];
    probabilities: ProbabilityType[];
    severities: SeverityType[];
    risks: RiskType[];
    users: User[];
    products: Product[];
    rootCauses: RootCause[];
  };
  event?: QualityEvent;
  related?: Relation[];
  suppliers?: QSelectItem[];
  relatedData?: {
    relatedDocuments: QSelectItem[];
    relatedEvents: QSelectItem[];
  };
  optionalFields?: EventTemplateField[] | null;
};

const EventForm: React.FC<Props> = ({
  formMethods,
  onSubmit,
  data,
  event,
  related,
  suppliers,
  optionalFields,
  relatedData,
}) => {
  let defaultRelated: QSelectItem[] = [];
  let defaultSourceEvents: QSelectItem[] = [];
  let defaultEscalatedTo: QSelectItem[] = [];

  const documentsSuggestionApi = quickSearchApiFactory('documents');
  const eventsSuggestionApi = quickSearchApiFactory('events');
  // nullable can be removed when feature flag qeConfigurableFields
  // is removed
  const optionalFieldsRender = optionalFields ?? [
    { attribute_name: 'product', mandatory: false },
    { attribute_name: 'root_cause', mandatory: false },
    { attribute_name: 'risk', mandatory: true },
  ];
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  let eventTypesOptions: QSelectItem[] = [];
  const { userId } = useCurrentUser();

  if (event) {
    eventTypesOptions = [{ value: String(event.workflow_id), label: event.workflow }];
  } else {
    eventTypesOptions = data.eventTypes
      .filter((eventType) => eventType.active)
      .map((eventType) => {
        return {
          value: String(eventType.id),
          label: eventType.name,
        };
      });
  }

  const eventTypeTimeLimit = Object.assign(
    {},
    ...data.eventTypes.map((x) => ({ [String(x.id)]: String(x.defaultTimeLimit) })),
  );

  const usersOptions = useUsersOptions(data.users, 'can_work_on_issue');

  useEffect(() => {
    setValue('owner_id', String(userId));
  }, [userId, setValue]);

  const eventTagsOptions = data.eventTags.map((tag) => ({
    value: String(tag.id),
    label: tag.name,
  }));

  const probabilitiesOptions = data.probabilities.map((probability) => ({
    value: probability.id,
    label: probability.name,
  }));

  const severitiesOptions = data.severities.map((severity) => ({
    value: severity.id,
    label: severity.name,
  }));

  const risksOptions = data.risks.map((risk) => ({
    value: risk.id,
    label: risk.name,
  }));

  const productsOptions = data.products.map((product) => ({
    value: String(product.id),
    label: product.name,
  }));

  const rootCausesOptions = data.rootCauses.map((rootCause) => ({
    value: String(rootCause.id),
    label: rootCause.name,
  }));

  if (related) {
    const filteredRelated = related.filter((item) => item.relation_direction === 'left');
    defaultRelated = filteredRelated.map((item) => ({
      value: item.uuid,
      label: item.label,
    }));
  }
  if (event?.escalated_from) {
    defaultSourceEvents = event.escalated_from.map((item) => ({
      value: String(item.id),
      label: `${item.code} ${item.title}`,
    }));
  }
  if (event?.escalated_to) {
    defaultEscalatedTo = event.escalated_to.map((item) => ({
      value: String(item.id),
      label: `${item.code} ${item.title}`,
    }));
  }

  const optionalfieldSelectOptions = {
    probabilitiesOptions,
    severitiesOptions,
    risksOptions,
    productsOptions,
    defaultRelated,
    defaultSupplier: suppliers ?? [],
    rootCausesOptions,
  };
  useEffect(() => {
    eventFieldsSetter(event, setValue);
  }, [event, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QStack direction="column" spacing={4}>
          <QFormControl
            label="Event type"
            error={errors.workflow_id && errors.workflow_id.message}
            isInvalid={!!errors.workflow_id}
            isRequired
            isReadOnly={true}
            data-metrics="event-type-select"
          >
            <Controller
              control={control}
              name="workflow_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QSelect
                  {...field}
                  onChange={(changeObject) => {
                    if (!changeObject) {
                      return;
                    }

                    if (!event) {
                      setValue('time_limit', eventTypeTimeLimit[changeObject.value]);
                    }
                    onChange(changeObject.value);
                  }}
                  options={eventTypesOptions}
                />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Title"
            error={errors.title && errors.title.message}
            isInvalid={!!errors.title}
            data-metrics="event-title-input"
            isRequired
          >
            <Controller
              control={control}
              name="title"
              render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
            />
          </QFormControl>
          <QFormControl
            label="Owner"
            error={errors.owner_id && errors.owner_id.message}
            isInvalid={!!errors.owner_id}
            data-metrics="event-owner-select"
            isRequired
          >
            <Controller
              control={control}
              name="owner_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QSelect
                  {...field}
                  onChange={(changeObject) => {
                    if (changeObject) {
                      onChange(changeObject.value);
                    }
                  }}
                  options={usersOptions}
                />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Time to resolve (days)"
            helper="Period of time within which the event must be resolved."
            data-metrics="event-time-to-resolve-input"
            error={errors.time_limit && errors.time_limit.message}
            isInvalid={!!errors.time_limit}
            isRequired
          >
            <Controller
              control={control}
              name="time_limit"
              render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
            />
          </QFormControl>
          <QFormControl label="Description" data-metrics="event-description-input">
            <Controller
              control={control}
              name="description"
              render={({ field: { ref: _ref, ...field } }) => <QTextarea {...field} />}
            />
          </QFormControl>
          <QFormControl label="Tags" data-metrics="event-tags-select">
            <Controller
              control={control}
              name="tags_ids"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QMultiSelect
                  {...field}
                  onChange={(val) => onChange(val.map((c) => c.value))}
                  options={eventTagsOptions}
                />
              )}
            />
          </QFormControl>

          <>
            <QFormControl label="Related events" data-metrics="event-related-event-select" isRequired={false}>
              <Controller
                control={control}
                name="related_events"
                render={({ field: { ref: _ref, onChange, ...field } }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    suggestionApi={eventsSuggestionApi}
                    defaults={relatedData?.relatedEvents}
                    valuePropFromSuggestion="qri"
                    domain="events"
                    omitFromSuggestionResults={event ? [event.id] : []}
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
            <QFormControl label="Related documents" data-metrics="event-related-document-select" isRequired={false}>
              <Controller
                control={control}
                name="related_documents"
                render={({ field: { ref: _ref, onChange, ...field } }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    suggestionApi={documentsSuggestionApi}
                    defaults={relatedData?.relatedDocuments}
                    valuePropFromSuggestion="qri"
                    domain="documents"
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
          </>

          <>
            <QFormControl label="Source event" data-metrics="event-source-event-select" isRequired={false}>
              <Controller
                control={control}
                name="escalated_from"
                render={({ field: { ref: _ref, onChange, ...field } }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    suggestionApi={eventsSuggestionApi}
                    defaults={defaultSourceEvents}
                    valuePropFromSuggestion="id"
                    domain="events"
                    omitFromSuggestionResults={event ? [event.id] : []}
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
            <QFormControl label="Escalated to" data-metrics="event-escalated-to-select" isRequired={false}>
              <Controller
                control={control}
                name="escalated_to"
                render={({ field: { ref: _ref, onChange, ...field } }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    suggestionApi={eventsSuggestionApi}
                    defaults={defaultEscalatedTo}
                    valuePropFromSuggestion="id"
                    domain="events"
                    omitFromSuggestionResults={event ? [event.id] : []}
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
          </>

          {optionalFieldsRender?.map((field) => (
            <EventFormOptionalFields
              fieldName={field.attribute_name}
              required={field.mandatory}
              formMethods={formMethods}
              selectOptionProps={optionalfieldSelectOptions}
              key={field.attribute_name}
            />
          ))}
        </QStack>
      </form>
    </FormProvider>
  );
};

export default EventForm;
