import { useQuery, UseQueryResult } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import { UserTasksListResponse, UserTasksRequest } from '../types';
import userTasksApi from '../api/userTasks.api';
import { DefaultErrorMessage } from '../displayStrings';

type UseTasksListType = {
  tasks: UserTasksListResponse | undefined;
  isTasksListLoading: boolean;
  isTasksListFetching: boolean;
  refetchTasks: () => Promise<UseQueryResult>;
};

export const useTasksList = (
  stepId: number,
  company: number,
  offset: number,
  filterParams: UserTasksRequest,
): UseTasksListType => {
  const { showToast } = useToastProvider();

  const {
    data: tasks,
    isLoading: isTasksListLoading,
    isFetching: isTasksListFetching,
    refetch: refetchTasks,
  } = useQuery(
    ['userTasksList', company, stepId, filterParams, offset],
    () => userTasksApi.getUserTasks(company, offset, filterParams),
    {
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );

  return { tasks, isTasksListLoading, isTasksListFetching, refetchTasks };
};
