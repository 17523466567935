import { medtechApi } from './index';
import { RegistryCategoriesResponseSchema } from '../types/registry';

const registryApi = {
  async getRegistryCategories(companyId: number) {
    const response = await medtechApi.get(`/v2/${companyId}/data-registry/categories`);

    return RegistryCategoriesResponseSchema.parse(response.data);
  },
};

export default registryApi;
