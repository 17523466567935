import React, { useCallback } from 'react';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { DigitalSignature, DigitalSignatureInput } from '../../types/digitalSignature';
import DigitalSignatureForm from './components/DigitalSignatureForm';
import { extractMessageFromError } from '../../utils/errorUtils';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (payload: DigitalSignature) => Promise<void>;
  inputTexts: DigitalSignatureInput;
  isCommentRequired: boolean;
  onSubmitError?: (err: unknown) => boolean;
};

const DigitalSignatureModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onSave,
  inputTexts,
  isCommentRequired,
  onSubmitError,
}) => {
  const { showToast } = useToastProvider();

  const formMethods = useForm<DigitalSignature>({
    mode: 'onSubmit',
    resolver: zodResolver(DigitalSignature),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = useCallback(
    async (data: DigitalSignature) => {
      try {
        await onSave(data);
        setIsOpen(false);
        showToast({
          title: inputTexts.successToastHeader,
          description: inputTexts.successToastDescription,
          status: 'success',
        });
        reset();
      } catch (err: any) {
        if (!onSubmitError?.(err)) {
          let errorMessage = extractMessageFromError(err);
          if (errorMessage === 'Invalid email address; Invalid email or password') {
            // explicitly indicating email is not correct is a security smell, so we replace the message in this case
            errorMessage = 'Invalid credentials';
          }
          showToast({
            title: 'Failed',
            description: errorMessage,
            status: 'error',
          });
        }
      }
    },
    [
      onSave,
      setIsOpen,
      showToast,
      inputTexts.successToastHeader,
      inputTexts.successToastDescription,
      reset,
      onSubmitError,
    ],
  );

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>{inputTexts.headingText}</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody pt={'2px'}>
        <QStack direction="column" color="gray.700" spacing={4}>
          <QText color="gray.800">{inputTexts.subText}</QText>
          <DigitalSignatureForm formMethods={formMethods} onSubmit={onSubmit} isCommentRequired={isCommentRequired} />
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          Cancel
        </QButton>
        <QButton onClick={() => handleSubmit(onSubmit)()} isDisabled={formHasErrors || isSubmitting}>
          {inputTexts.submitButtonText}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default DigitalSignatureModal;
