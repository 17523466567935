import { FeatureFlagProviderCreator, KnownFlags } from './shared';
import * as realLd from './standardImplementation';
import * as fakeLd from './localDevImplementation';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
// eslint-disable-next-line no-restricted-imports
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

export const CreateLaunchDarklyProvider: FeatureFlagProviderCreator = (
  user: CurrentUser,
): ReturnType<typeof asyncWithLDProvider> => {
  return process.env.REACT_APP_ENVIRONMENT === 'development'
    ? fakeLd.CreateLaunchDarklyProvider(user)
    : realLd.CreateLaunchDarklyProvider(user);
};

export function useFlags(flagName: KnownFlags): boolean;
export function useFlags<T extends KnownFlags>(flagName: T[]): Record<T, boolean>;
export function useFlags(flagName: KnownFlags | KnownFlags[]) {
  return process.env.REACT_APP_ENVIRONMENT === 'development' ? fakeLd.useFlags(flagName) : realLd.useFlags(flagName);
}

export type { KnownFlags };
export const asKnownFlags = (flags: string[]) => {
  return flags as KnownFlags[];
};
