import { User } from '../types';

export const filterByPermission = (users: User[], permission: string) => {
  return users.filter((u) => u.permissions.includes(permission));
};

export const getUserTZ = (userId: number, usersData: User[]): string => {
  const tz = usersData?.find((user) => user.id === userId)?.tz;
  return tz || 'UTC';
};
