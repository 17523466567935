import { BatchQriDetailsSchema } from '../types/relatedData';
import { qriApi } from './index';

/**
 * @deprecated Please use qriServiceApi (qriServiceApi.api.ts) instead.
 */
const qriJson = {
  async batchGet(qris: string[]) {
    if (!qris.length) {
      return undefined;
    }

    const data = {
      qris,
      short: false,
    };
    const res = await qriApi.post(`/json`, data);
    const result = BatchQriDetailsSchema.safeParse(res.data);
    return result.success ? result.data : undefined;
  },
};

export default qriJson;
