import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';
import * as CKE from '@ckeditor/ckeditor5-react';
import React from 'react';
import { CKEditorConfig } from '../CKEditor/config/CKEditorConfig';
import { getCKEditorConfig } from '../CKEditor/config/EditorConfig';
import { QualioSectionedContent } from '../CKEditor/models';
import { QualioAttachmentCKUploadAdapter } from '../CKEditor/plugins/Attachment/QualioAttachmentCKUploadAdapter';
import { updateEditorSelectionToEditableRegion } from '../CKEditor/plugins/LockSectionHeaders/utils';
import { CKEditorInstance } from '../CKEditor/types/CKEditorInstance';
import { PresentationUtils } from '../CKEditor/util/PresentationUtils';
import { ToolbarManager, useToolbarManager } from '../CKEditor/util/ToolbarManager';
import styles from './ContentEditor.module.css';
import { CONTENT_EDITOR_TOOLBAR_ITEMS } from '../CKEditor/config/ToolbarItems';
import { useFlags } from '../../../external/LaunchDarklyAdapter';

const configureUploadAdapter = (editor: CKEditorInstance) => {
  const fileRepositoryPlugin = editor.plugins.get('FileRepository');
  fileRepositoryPlugin.createUploadAdapter = (loader: any) => {
    return new QualioAttachmentCKUploadAdapter(loader);
  };
};

export const setupClickListener = (editor: CKEditorInstance): void => {
  editor.editing.view.listenTo(editor.editing.view.document, 'click', (evt: any, data: any) => {
    const clickedViewElement = data.target;
    const href = clickedViewElement.getAttribute('href');
    // always open smart links in new tab
    if (clickedViewElement.getAttribute('data-mention') && href) {
      window.open(href, '_blank');
      return;
    }
  });
};

function mathCharactersExtended(editor: CKEditorInstance): void {
  const scp = editor.plugins.get('SpecialCharacters');
  scp.addItems('Mathematical', [{ title: 'delta', character: 'Δ' }]);
}

const createEditorReadyHandler =
  (toolbarManager: ToolbarManager, isSectionedContent: boolean, focusOnRender: boolean) =>
  (editor: CKEditorInstance): void => {
    if (focusOnRender) {
      editor.focus();
    }
    if (isSectionedContent) {
      updateEditorSelectionToEditableRegion(editor);
    }
    setupClickListener(editor);
    mathCharactersExtended(editor);
    toolbarManager.setToolbarFromEditor(editor);
    configureUploadAdapter(editor);
    editor.model.schema.setAttributeProperties('htmlAttributes', {
      isFormatting: true,
    });
  };

export const ContentEditor: React.FC<{
  sectionedData?: QualioSectionedContent;
  data?: string;
  companyId: number;
  handleChange: (event: any, editor: CKEditorInstance) => Promise<void>;
  toolbarItems?: string[];
  toolbarParentId?: string;
}> = ({
  sectionedData,
  data,
  companyId,
  handleChange,
  toolbarItems = CONTENT_EDITOR_TOOLBAR_ITEMS,
  toolbarParentId = 'pageHeaderSection',
}) => {
  const isSmartlinkEverythingEnabled = useFlags('smartlinkEverything');

  const editorConfig: CKEditorConfig = {
    ...getCKEditorConfig(companyId, isSmartlinkEverythingEnabled, toolbarItems),
  };

  const toolbarManager = useToolbarManager(toolbarParentId);
  const toolbarCSS = toolbarParentId === 'pageHeaderSection' ? '' : styles['form-toolbar'];
  const contentCSS = toolbarParentId === 'pageHeaderSection' ? '' : styles['form-content'];

  // toolbarParentid is only present for Form Rich Text Editor
  // Hence comparing with default value to validate it
  const focusOnRender = toolbarParentId === 'pageHeaderSection';

  return (
    <>
      <div className={`${styles['sections-editor__heading-section']} ${toolbarCSS}`} id={toolbarParentId} />
      <div
        data-cy={'ContentEditor'}
        id="ContentEditor"
        className={`${styles['sections-editor__document__section-content']} ${contentCSS} document `}
      >
        <CKE.CKEditor
          onReady={createEditorReadyHandler(toolbarManager, sectionedData !== undefined, focusOnRender)}
          onChange={handleChange}
          editor={DecoupledEditor}
          watchdogConfig={{ saveInterval: 30000 }}
          data={sectionedData ? PresentationUtils.determineEditorContentFromSectionsData(sectionedData) : data}
          config={editorConfig}
        />
      </div>
    </>
  );
};
