import React from 'react';
import {
  QBox,
  QButton,
  QCloseButton,
  QFormControl,
  QHeading,
  QInput,
  QSelect,
  QSpacer,
  QStack,
  QText,
} from '@qualio/ui-components';
import * as DisplayStrings from '../../displayStrings';

type StepInputProps = {
  title: string;
  submitText: string;
  submitLabel: string;
  closeInput: () => void;
  submit: (stepName: string, stepType: string) => void;
  currentName?: string;
  currentType?: string;
  disableType?: boolean;
  checkDuplicated?: (stepName: string) => boolean;
};

const StepTypeOptions = [
  { value: 'document', label: 'Content' },
  { value: 'actions', label: 'Tasks' },
  { value: 'form', label: 'Form' },
] as const satisfies readonly { value: string; label: string }[];

const StepInput = ({
  title,
  submitText,
  submitLabel,
  closeInput,
  submit,
  currentName = '',
  currentType = '',
  disableType = false,
  checkDuplicated = () => false,
}: StepInputProps) => {
  const [stepName, setStepName] = React.useState(currentName);
  const [stepType, setStepType] = React.useState(currentType);
  const stepNameIsDuplicated = checkDuplicated(stepName);
  const saveButtonDisabled = stepName.trim() === '' || !stepType || stepNameIsDuplicated;

  return (
    <QBox borderColor="gray.100" borderWidth="1px" bg="gray.50" borderRadius="md" padding={5}>
      <QStack direction="row">
        <QHeading size="sm">{title}</QHeading>
        <QSpacer />
        <QCloseButton onClick={closeInput} />
      </QStack>
      <QStack direction="row">
        <QBox width="70%">
          <QFormControl label="Name">
            <QInput
              name="step-name"
              data-cy={'step-name'}
              aria-label="step-name"
              bg="white"
              value={stepName}
              onChange={(changeEvent) => {
                setStepName(changeEvent.target.value);
              }}
            />
          </QFormControl>
          {stepNameIsDuplicated === true && <QText color={'red'}>Duplicated step names!</QText>}
        </QBox>
        {disableType === true ? (
          <QFormControl data-cy="step-type" label="Type" width="30%">
            <QInput name="step-type" bg="white" isDisabled={true} value={stepType} />
          </QFormControl>
        ) : (
          <QFormControl data-cy="step-type" label="Type" width="30%">
            <QBox bg={'white'}>
              <QSelect
                name="step-type"
                options={StepTypeOptions}
                value={stepType}
                onChange={(changeObject) => {
                  const newValue = changeObject ? changeObject.value : '';

                  setStepType(newValue);
                }}
              />
            </QBox>
          </QFormControl>
        )}
      </QStack>
      <QStack direction="row-reverse" mt={4}>
        <QButton
          isDisabled={saveButtonDisabled}
          onClick={() => submit(stepName, stepType)}
          aria-label={submitLabel}
          data-cy={submitLabel}
        >
          {submitText}
        </QButton>
        <QButton data-cy={'cancel-template-step'} variant="outline" onClick={closeInput}>
          {DisplayStrings.Cancel}
        </QButton>
      </QStack>
    </QBox>
  );
};

export default StepInput;
