import { EventTemplateDetailsResponse, EventTemplateFormValues } from '../types';
import { elementAtIndex } from './arrayUtils';

export const convertResponseToTemplateFormValues = (data: EventTemplateDetailsResponse): EventTemplateFormValues => {
  const { name, prefix, default_time_limit, steps, fields } = data;
  const start_time_constraint = Number(elementAtIndex(steps, steps.length - 1).start_time_constraint);

  return {
    name,
    prefix,
    default_time_limit,
    start_time_constraint,
    fields: fields.reduce(
      (acc, field) => {
        acc[field.attribute_name] = field;
        return acc;
      },
      {} as EventTemplateFormValues['fields'],
    ),
    steps: steps,
  };
};
