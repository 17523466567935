import { QriDetails } from '../../../../types';

export function formResolvedQriDisplayText(qriDetails: QriDetails) {
  if (qriDetails.identifiers.code) {
    if (['user', 'registry-item'].includes(qriDetails.resource)) {
      return `${qriDetails.name} (${qriDetails.identifiers.code})`;
    }
    return `${qriDetails.identifiers.code} ${qriDetails.name}`;
  }
  return qriDetails.name;
}
