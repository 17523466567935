import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MainContainer, Navigation } from './components';
import { UserPermissionsProvider } from './context';
import {
  QualityEventListOverview,
  QualityEventDetails,
  ProductListOverview,
  RootCauseListOverview,
  TaskDetailsProxy,
  QualityEventTemplates,
  EventTemplate,
} from './views';
import EditContentTemplate from './views/EditContentTemplate/EditContentTemplate';
import RouteWatcher from './components/RouteWatcher/RouteWatcher';
import FormEditorOverview from './views/FormEditorOverview/FormEditorOverview';
import EventStepForm from './views/EventStepForm/EventStepForm';
import { useUserRole } from './hooks/useUserRole';

const App: React.FC = () => {
  const userType = useUserRole();

  return (
    <MainContainer>
      <UserPermissionsProvider userType={userType ?? ''}>
        <Router basename={'/quality-events/'}>
          <RouteWatcher />
          <Routes>
            <Route element={<Navigation />}>
              <Route index element={<QualityEventListOverview />} />
              <Route path="events" element={<QualityEventListOverview />} />
              <Route path="products" element={<ProductListOverview />} />
              <Route path="root-causes" element={<RootCauseListOverview />} />
            </Route>
            <Route path="templates" element={<QualityEventTemplates />} />
            <Route path="templates/new" element={<EventTemplate viewType="new" />} />
            <Route path="templates/:eventTemplateId" element={<EventTemplate viewType="edit" />} />
            <Route
              path="templates/:eventTemplateId/content-templates/:contentTemplateId/edit"
              element={<EditContentTemplate />}
            />
            <Route
              path="templates/:eventTemplateId/form-templates/:formTemplateId/edit"
              element={<FormEditorOverview />}
            />
            <Route path="events/:eventId/steps/:stepId/forms/:formId" element={<EventStepForm viewType="view" />} />
            <Route
              path="events/:eventId/steps/:stepId/forms/:formId/edit"
              element={<EventStepForm viewType="edit" />}
            />
            <Route path="events/:eventId" element={<QualityEventDetails />} />
            <Route path="tasks/:taskId" element={<TaskDetailsProxy />} />
          </Routes>
        </Router>
      </UserPermissionsProvider>
    </MainContainer>
  );
};

export default App;
