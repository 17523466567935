import React, { useCallback, useState } from 'react';
import { QButton, QStack, useCurrentUser } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';
import { EventStep, User, UpdateForm } from '../../../types';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import StepBody from './StepBody';
import EmptyStep from './EmptyStep';
import { useEventPermissions } from '../../../hooks';
import FormStepMetadata from './FormStepMetadata/FormStepMetadata';
import { useAssociateForm } from '../../../hooks/useAssociateForm';
import { useFormDetails } from '../../../hooks/useEventForm';
import ViewFormButton from './ViewFormButton';
import FormApproversList from './FormApproversList';
import { useQuery, useQueryClient } from 'react-query';
import approversApi from '../../../api/approvers.api';
import AssignFormModal from '../../../views/EventStepForm/components/AssignFormModal';
import DigitalSignatureModal from '../../DigitalSignatureModal/DigitalSignatureModal';
import { DigitalSignature, DigitalSignatureInput } from '../../../types/digitalSignature';
import issueStepsApi from '../../../api/issueSteps.api';
import StepStartTimeConstraintInfo from './StepStartTimeConstraintInfo';
import * as DisplayStrings from '../../../displayStrings';

type FormStepProps = {
  step: EventStep;
  users: User[];
  lastUpdated: number | null;
};

const FormStep = ({ step, users, lastUpdated }: FormStepProps) => {
  const stepId = step.id;
  const eventId = step.issue_id;
  const formId = step.form_id;
  const { canAddFormToStep, canRevertStep } = useEventPermissions();
  const stepStatus = step.status;
  const stepNotCompleted = !['completed', 'closed'].includes(stepStatus);
  const { companyId } = useCurrentUser();
  const [isAssignFormModalOpen, setIsAssignFormModalOpen] = useState<boolean>(false);
  const [isRevertStepModalOpen, setIsRevertStepModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: form, isLoading: isFormLoading } = useFormDetails(eventId, stepId, formId as number);
  const { data: existingReviewers, isLoading: isReviewersLoading } = useQuery(
    ['reviewersList', formId],
    () => approversApi.getApprovers(companyId, formId as number, 'reviewers'),
    { enabled: !!formId },
  );

  const { data: existingApprovers, isLoading: isApproversLoading } = useQuery(
    ['approversList', formId],
    () => approversApi.getApprovers(companyId, formId as number, 'approvers'),
    { enabled: !!formId },
  );
  const { mutate: associateFormMutate, isLoading: isAssociateFormLoading } = useAssociateForm();
  const navigateToFormView = useCallback(
    (form: UpdateForm) => {
      navigate(`/events/${eventId}/steps/${stepId}/forms/${form.id}`);
    },
    [eventId, navigate, stepId],
  );
  const navigateToFormEdit = useCallback(
    (form: UpdateForm) => {
      navigate(`/events/${eventId}/steps/${stepId}/forms/${form.id}/edit`);
    },
    [eventId, navigate, stepId],
  );

  const assignStepButton = () => {
    return (
      <QButton
        variant="ghost"
        leftIcon="User"
        isDisabled={!canAddFormToStep}
        onClick={() => setIsAssignFormModalOpen(true)}
        data-metrics="assign-step-button"
        data-cy="assign-step"
      >
        Assign step
      </QButton>
    );
  };

  const revertStepModalInput: DigitalSignatureInput = {
    headingText: 'Revert step completion?',
    subText: `Sign off to revert the completion of the "${step.label}" step`,
    submitButtonText: 'Revert step',
    successToastHeader: 'Step reverted',
    successToastDescription: `"${step.label}" step completion was reverted`,
  };

  const handleRevertStep = async (payload: DigitalSignature) => {
    await issueStepsApi.revert(companyId, step.issue_id, step.id, payload);
    queryClient.invalidateQueries({ queryKey: ['eventDetails', companyId, String(eventId)] });
  };

  const RevertStepButton = () => (
    <QButton
      variant="ghost"
      leftIcon="CornerUpLeft"
      onClick={() => setIsRevertStepModalOpen(true)}
      isDisabled={!canRevertStep}
      data-metrics="step-header-revert-step-button"
    >
      Revert
    </QButton>
  );

  return (
    <StepContainer>
      <StepHeader
        title={step.label}
        status={stepStatus}
        headerButton={
          formId ? <ViewFormButton eventId={eventId} stepId={stepId} formId={formId} /> : assignStepButton()
        }
        revertStepButton={<RevertStepButton />}
      />
      <StepBody isLoading={isFormLoading || isReviewersLoading || isApproversLoading}>
        <FormStepMetadata form={form} />
        {!!step.start_time_constraint && form?.status === 'draft' && (
          <StepStartTimeConstraintInfo startTimeConstraint={step.start_time_constraint} lastUpdated={lastUpdated} />
        )}
        {formId && form ? (
          <>
            <QStack direction="row" spacing={20} mt={4} ml={4}>
              <FormApproversList label="Approvers" approvers={existingApprovers ?? []} />
              <FormApproversList label="Reviewers" approvers={existingReviewers ?? []} />
            </QStack>
          </>
        ) : (
          <EmptyStep
            text={
              stepNotCompleted
                ? 'Complete form and send it for review and approval when ready.'
                : 'There is no form associated with this step.'
            }
          >
            {stepNotCompleted && (
              <QButton
                variant="ghost"
                leftIcon="Edit3"
                onClick={() => associateFormMutate({ eventId, stepId }, { onSuccess: navigateToFormEdit })}
                isLoading={isAssociateFormLoading}
                isDisabled={!canAddFormToStep}
                data-cy="complete-form"
              >
                {DisplayStrings.CompleteForm}
              </QButton>
            )}
          </EmptyStep>
        )}
      </StepBody>
      <AssignFormModal
        isOpen={isAssignFormModalOpen}
        setIsOpen={setIsAssignFormModalOpen}
        onSave={(rawOwnerId) => {
          const ownerId = Number(rawOwnerId);
          associateFormMutate({ eventId, stepId, ownerId }, { onSuccess: navigateToFormView });
        }}
        users={users ?? []}
        reassign={false}
        eventStepName={step.label}
      />
      <DigitalSignatureModal
        isOpen={isRevertStepModalOpen}
        setIsOpen={setIsRevertStepModalOpen}
        onSave={handleRevertStep}
        inputTexts={revertStepModalInput}
        isCommentRequired={false}
      />
    </StepContainer>
  );
};

export default FormStep;
