import { CurrentUserContextType, useToastProvider } from '@qualio/ui-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EventType } from '../../types';
import eventTypesApi from '../../api/eventTypes.api';
import templatesApi from '../../api/templates.api';

const BASE_QUERY_KEY = 'eventTypesList';

type CompanyId = CurrentUserContextType['companyId'];
type TemplateId = EventType['id'];

export const useGetTemplates = (companyId: CompanyId) => {
  return useQuery({
    queryKey: [BASE_QUERY_KEY, companyId],
    queryFn: () => eventTypesApi.getAllEventTypes(companyId, true),
  });
};

export const useArchiveTemplate = (companyId: CompanyId) => {
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: (templateId: TemplateId) => templatesApi.archiveTemplate(companyId, templateId),
    onSuccess: () => {
      queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]);

      showToast({
        title: 'Template archived',
        description: 'Template has been archived',
        status: 'success',
      });
    },
    onError: () => {
      showToast({
        title: 'Template archiving failed',
        description: 'Error occurred while archiving template',
        status: 'error',
      });
    },
  });
};

export const useRestoreTemplate = (companyId: CompanyId) => {
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: (templateId: TemplateId) => templatesApi.restoreTemplate(companyId, templateId),
    onSuccess: () => {
      queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]);

      showToast({
        title: 'Template restored',
        description: 'Template has been restored',
        status: 'success',
      });
    },
    onError: () => {
      showToast({
        title: 'Template restoring failed',
        description: 'Error occurred while restoring template',
        status: 'error',
      });
    },
  });
};
